import { Button } from 'components/Button/Button';
import { useFormState } from 'react-hook-form';
import { MuiStyledFooterWrapper } from 'shared/CompositeComponents/2FASetupFlow/TwoFaSetup.styles';

export const FormFooter = ({
  submitButtonText,
  onCancel,
  isLoading,
}: {
  submitButtonText: string;
  onCancel?: () => void;
  isLoading: boolean;
}) => {
  const data = useFormState();

  return (
    <MuiStyledFooterWrapper>
      <Button data-qa-id="cancelButton" variant="secondary" type="button" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        data-qa-id="createExchangesButton"
        type="submit"
        disabled={!data.isValid}
        isLoading={isLoading}
        variant="primary"
      >
        {submitButtonText}
      </Button>
    </MuiStyledFooterWrapper>
  );
};
