import { ButtonProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import spaceTokens from 'theme/utils/spacers';
import { primaryButton } from './variants/primaryButton';
import { dangerButton } from './variants/dangerButton';
import { tertiaryButton } from './variants/tertiaryButton';
import { secondaryButton } from './variants/secondaryButton';

export function button(theme: Theme) {
  return {
    MuiButton: {
      variants: [
        ...primaryButton(theme),
        ...secondaryButton(theme),
        ...dangerButton(theme),
        ...tertiaryButton(theme),
      ],
      defaultProps: {
        borderRadius: spaceTokens['spacing-08'],
        disableElevation: true,
        size: 'medium',
      },
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ButtonProps }) => {
          const smallSize = ownerState.size === 'small';
          const mediumSize = ownerState.size === 'medium';
          const largeSize = ownerState.size === 'large';

          const size = {
            ...(smallSize && {
              height: 32,
              padding: theme.spacing(1, 1.5),
            }),
            ...(mediumSize && {
              height: 40,
              padding: theme.spacing(1.5, 2),
            }),
            ...(largeSize && {
              height: 48,
              padding: theme.spacing(2, 2.25),
            }),
          };
          return {
            ...size,
            fontSize: 14,
            gap: theme.spacing(1),
            fontWeight: 500,
            minWidth: 'max-content',
            '&:focus-visible': {
              boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            },
            svg: {
              color: 'inherit',
            },
            '.MuiButton-endIcon': {
              marginInline: 0,
            },
            '.MuiButton-startIcon': {
              marginInline: 0,
            },
          };
        },
      },
    },
  };
}
