import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table from 'components/Table';
import TableHeader from 'components/Table/TableHeader';
import isEmpty from 'lodash/isEmpty';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppModal from 'hooks/useAppModal';
import { CONSTITUENT_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { ProductConstituentAssetType, TokenConstituentAssetType } from 'utils/types/product';
import { ConstituentsTableRow } from './ConstituentsTableRow';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { TableHeaderColumn } from 'components/Table/Table.types';
import { createNotification } from 'store/notifications/actions';
import { useSortHook } from 'hooks/useSortHook';
import { AssetClass } from 'utils/types/product';
import { useMemo, useState } from 'react';
import { Button } from 'components/Button/Button';
import { useCurrentProduct } from 'hooks/useCurrentProduct';
import { Box, Grid } from '@mui/material';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';

interface ConstituentsTableProps {
  constituentsList: ProductConstituentAssetType[] | TokenConstituentAssetType[];
  loading: boolean;
  deleteAction: (constituent: TokenConstituentAssetType | ProductConstituentAssetType) => void;
  isToken?: boolean;
}

const filterConsituents = (
  clist: ProductConstituentAssetType[] | TokenConstituentAssetType[],
  cType: AssetClass[]
) => {
  if (!cType.length) return clist;
  return clist.filter((el) => cType.includes(el.assetType));
};

const columns = [
  {
    label: 'Ticker',
    propName: 'ticker',
    propType: 'string',
    sort: false,
    width: '18%',
  },
  { label: 'Name', propName: 'name', propType: 'string', sort: false, width: '18%' },
  {
    label: 'Asset Class',
    propName: 'assetType',
    propType: 'string',
    sort: false,
    width: '14%',
  },
  {
    label: 'Staking',
    propName: 'stakingFee',
    propType: 'string',
    sort: false,
    width: '14%',
  },
  {
    label: 'Interest Generating',
    propName: 'interestGenerating',
    propType: 'string',
    sort: false,
    width: '14%',
  },
  {
    label: 'Leveraged',
    propName: 'isLeveraged',
    propType: 'string',
    sort: false,
    width: '14%',
  },
  {
    label: 'Status',
    propName: 'status',
    propType: 'string',
    sort: false,
    width: '14%',
  },
];

export const ConstituentsTable = ({
  constituentsList,
  loading,
  deleteAction,
  isToken,
}: ConstituentsTableProps) => {
  const openModal = useAppModal();
  const dispatch = useAppDispatch();
  const { product } = useCurrentProduct(isToken ? 'Token' : 'ETP');
  const [constituentType, setConstituentType] = useState<AssetClass[]>([]);
  const filteredConstituents = useMemo(
    () => filterConsituents(constituentsList, constituentType),
    [constituentType, constituentsList]
  );

  const { sortedList, handleSortProp } = useSortHook<
    ProductConstituentAssetType | TokenConstituentAssetType
  >(filteredConstituents);

  const addNewConstituentButtonClick = () => {
    if (
      product?.type !== 'Single Asset' ||
      !product?.constituentAssets?.length ||
      product?.constituentAssets?.length < 1
    ) {
      openEditConstituentModal(MODAL_ACTIONS.CREATE);
    } else {
      dispatch(
        createNotification({
          message: `You can not add more than one Constituent asset for Single Asset ${
            isToken ? 'Token' : 'ETP'
          }.`,
          title: `Single Asset ${isToken ? 'Token' : 'ETP'}`,
          type: 'info',
        })
      );
    }
  };

  const openEditConstituentModal = (
    type: MODAL_ACTIONS,
    constituent?: ProductConstituentAssetType | TokenConstituentAssetType
  ) => {
    openModal({
      modalName: CONSTITUENT_MODAL,
      modalData: {
        data: {
          constituent,
          constituents: constituentsList,
        },
        custom: {
          isToken: Boolean(isToken),
        },
        type: type,
      },
    });
  };

  return (
    <>
      <Box paddingLeft={2} paddingRight={2}>
        <TableTitleWithActions
          title="Constituent Assets"
          actions={[
            <Grid
              item
              display={'flex'}
              alignItems={'center'}
              justifyContent={'end'}
              gap={2}
              xs={12}
              md={6}
            >
              <MultiSelect
                placeholder="Filter"
                options={[
                  { label: 'Crypto', value: 'CRYPTO' },
                  { label: 'Metal', value: 'METAL' },
                  { label: 'Cash', value: 'CASH' },
                ]}
                onChange={(_, values) => setConstituentType(values as AssetClass[])}
                value={constituentType as string[]}
                fullWidth={false}
              />
              <Button
                data-qa-id="addConstituentBtn"
                variant="secondary"
                onClick={addNewConstituentButtonClick}
                type="button"
                size="medium"
                endIcon={<PlusIcon />}
              >
                Add Constituent
              </Button>
            </Grid>,
          ]}
          borderBottom={false}
        />
      </Box>
      <Table>
        <TableHeader
          showActionsCell
          columns={columns}
          onColumnSort={(col: TableHeaderColumn) => {
            handleSortProp(col);
          }}
        />
        <TableBodyWithStates
          loadingData={Boolean(loading)}
          hasContent={!isEmpty(sortedList)}
          noContentLabel="No constituents at the moment."
        >
          {sortedList.map((constituent, index) => (
            <ConstituentsTableRow
              data-qa-id={constituent}
              key={(constituent._id ?? '') + index}
              index={index}
              constituent={constituent}
              editAction={() => {
                openEditConstituentModal(MODAL_ACTIONS.EDIT, constituent);
              }}
              deleteAction={() => {
                deleteAction(constituent);
              }}
              showActionsCell
            />
          ))}
        </TableBodyWithStates>
      </Table>
    </>
  );
};
