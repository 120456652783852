import { useState, ComponentPropsWithoutRef, useEffect, useCallback } from 'react';
import { MuiInputWrapper } from './style';
import { sizeVariantType, inputType } from 'components/BooleanInputs/types';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Radio as MuiRadio,
  Switch,
  Typography,
} from '@mui/material';

interface BasicProps {
  disabled?: boolean;
  checked?: boolean;
  label?: string;
  name?: string;
  onChange?: (isChecked: boolean) => void;
  sizeVariant?: sizeVariantType;
  title?: string;
}

export interface CheckboxProps extends Omit<BasicProps, 'label'> {
  label?: React.ReactNode | string;
  dash?: boolean;
}

export interface ToggleProps extends BasicProps {
  switchLabelWithTitle?: boolean;
}

interface BooleanInputProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'>,
    CheckboxProps {
  inputType?: inputType;
}

const inputTypeToComponent = {
  checkbox: MuiCheckbox,
  radio: MuiRadio,
  toggle: Switch,
};

const NewBooleanInput = ({
  inputType,
  label,
  checked = false,
  disabled,
  name = '',
  onChange,
  title,
}: BooleanInputProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    if (isChecked !== checked) {
      setIsChecked(checked);
    }
  }, [checked]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(event.target.checked);
      if (onChange) {
        onChange(event.target.checked);
      }
    },
    [onChange]
  );

  const InputComponent = inputTypeToComponent[inputType || 'checkbox'];

  return (
    <MuiInputWrapper>
      {title && <Typography>{title}</Typography>}
      <FormControlLabel
        control={
          <InputComponent
            checked={isChecked}
            onChange={handleChange}
            disabled={disabled}
            name={name}
          />
        }
        label={label}
      />
    </MuiInputWrapper>
  );
};

const Checkbox = (props: CheckboxProps) => <NewBooleanInput {...props} inputType="checkbox" />;
const Radio = (props: BasicProps) => <NewBooleanInput {...props} inputType="radio" />;
const Toggle = (props: ToggleProps) => <NewBooleanInput {...props} inputType="toggle" />;

export { Checkbox, Radio, Toggle };
export default NewBooleanInput;
