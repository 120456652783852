import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Partner } from 'utils/types';
import { createNotification } from 'store/notifications/actions';
import { deleteCompanyRequest } from 'utils/api/partners';
import { partnerDeactivatedNotification } from 'shared/Notifications/partners.notifications';
import { useMutation } from 'react-query';
import { queryClient } from 'utils/api/queries/queryClient';
import {
  getCompaniesQuery,
  getCompanyQuery,
  getCompanySuggestionQuery,
} from 'utils/constants/reactQueries';
import { Box, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const DeletePartnerModal = ({ onCloseModalAction, data, closeModal }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const partner = data.data as Partner;
  const deleteUriPath = partner?._actions?.delete?.uri ?? '';

  const deletePartnerMutation = useMutation({
    mutationFn: () => {
      return deleteCompanyRequest(deleteUriPath);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        getCompanyQuery,
        getCompaniesQuery,
        getCompanySuggestionQuery,
      ]);
      dispatch(createNotification(partnerDeactivatedNotification.success(partner.name)));
      closeModal();
      if (onCloseModalAction) onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(partnerDeactivatedNotification.error(err.message), err));
    },
  });

  const handleDelete = () => {
    deletePartnerMutation.mutate();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button variant="secondary" onClick={closeModal} type="button" data-qa-id="cancelButton">
        Cancel
      </Button>
      <Button
        variant="danger"
        onClick={handleDelete}
        type="submit"
        data-qa-id="deactivateButton"
        isLoading={deletePartnerMutation.isLoading}
      >
        Deactivate
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={<CardHeaderTitleWithLabel label="Partners" title="Deactivate Partner" />}
        body={
          <Box padding={2}>
            <Typography variant="bodyMedium">
              Are you sure you want to deactivate {partner.name}?
            </Typography>
          </Box>
        }
        footer={<Box padding={2}>{Footer}</Box>}
        onClose={closeModal}
      />
    </CustomModal>
  );
};
