import { IModalWithData } from 'shared/Modals/types';
import { Reset2FA } from './Reset2FA/Reset2FA';
import { SendInvitationModal } from './SendInvitationModal/SendInvitationModal';
import { ReactivateUserModal } from './ReactivateUser/ReactivateUserModal';

export type CustomUserModalType = 'reset2FA' | 'reactivate' | 'resendInvitation';
export const CustomUserModal = ({ ...props }: IModalWithData) => {
  if (props.data?.custom?.type === 'reset2FA') return <Reset2FA {...props} />;
  if (props.data?.custom?.type === 'reactivate') return <ReactivateUserModal {...props} />;
  return <SendInvitationModal {...props} />;
};
