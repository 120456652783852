// 21co colors
export const colors = {
  neutral: {
    0: '#FFFFFF',
    10: '#F9FBFD',
    20: '#F0F4F7',
    30: '#D2DBE4',
    40: '#BFC8D2',
    50: '#838F98',
    60: '#3B4853',
    70: '#242C33',
    80: '#1A1E23',
    90: '#101012',
    100: '#101012',
    110: '#0E0F10',
  },
  yellow: {
    10: '#FEF9EF',
    20: '#FBEED2',
    30: '#F8DCA6',
    40: '#F4CB79',
    50: '#F1B94D',
    60: '#EDA820',
    70: '#C48A18',
    80: '#9A6C11',
    90: '#714E09',
    100: '#2F2104',
  },
  gold: {
    10: '#FAF7F3',
    20: '#F5EDE2',
    30: '#EFE3D1',
    40: '#EAD5BB',
    50: '#B6A48E',
    60: '#837260',
    70: '#4F453A',
    80: '#4F4133',
    90: '#2E261E',
    100: '#1F1A14',
  },
  orange: {
    10: '#FFF8F3',
    20: '#FFE4D6',
    30: '#FFC2A3',
    40: '#FFAA80',
    50: '#FF8F57',
    60: '#FF7A37',
    70: '#D55810',
    80: '#AF430D',
    90: '#662200',
    100: '#331100',
  },
  red: {
    10: '#FFEEEE',
    20: '#FFCCCC',
    30: '#FF9999',
    40: '#FF6666',
    50: '#FF3333',
    60: '#D2222C',
    70: '#CC0000',
    80: '#990000',
    90: '#660000',
    100: '#330000',
  },
  cyan: {
    10: '#F1FCFC',
    20: '#D6F5F5',
    30: '#ADEBEB',
    40: '#84E1E1',
    50: '#5BD7D7',
    60: '#32CDCD',
    70: '#28A4A4',
    80: '#1E7B7B',
    90: '#145252',
    100: '#0A2929',
  },
  green: {
    10: '#F1FFF5',
    20: '#D7F4DE',
    30: '#B0E8BD',
    40: '#88DD9C',
    50: '#61D17B',
    60: '#47BC62',
    70: '#2E9E48',
    80: '#227736',
    90: '#145222',
    100: '#0A2911',
  },
  blue: {
    10: '#F1F7FF',
    20: '#CEE1FD',
    30: '#9DC3FB',
    40: '#6CA4F9',
    50: '#3B86F7',
    60: '#0A68F5',
    70: '#0853C4',
    80: '#063E93',
    90: '#042A62',
    100: '#021531',
  },
};

/* ----- 
Types
----- */
export type TextTokens = {
  weak: string;
  standard: string;
  strong: string;
  action: string;
  disabled: string;
};
export type ButtonState = {
  standard: string;
  hover: string;
  pressed: string;
  disabled: string;
};

export type ButtonBackgroundTokens = {
  primary: ButtonState;
  secondary: ButtonState;
  tertiary: ButtonState;
  danger: ButtonState;
};

export type BackgroundTokens = {
  weak: string;
  standard: string;
  strong: string;
};

export type SentimentTextTokens = {
  negative: string;
  positive: string;
  notice: string;
  info: string;
  neutral: string;
  primary: string;
};

export type SupportTokens = {
  weak: string;
  standard: string;
  strong: string;
};

export type InputBorderTokens = {
  standard: string;
  hover: string;
  active: string;
  focus: string;
  disabled: string;
};

export type DividerTokens = {
  weak: string;
  standard: string;
  strong: string;
};

export type ThemeTokens = {
  // background colors
  background: BackgroundTokens;
  // text colors
  text: TextTokens;
  // sentiment specific colors
  sentiment: {
    text: SentimentTextTokens;
  };
  // support specific colors (icons, borders etc)
  support: {
    negative: SupportTokens;
    positive: SupportTokens;
    notice: SupportTokens;
    info: SupportTokens;
    neutral: SupportTokens;
    primary: SupportTokens;
  };
  // button specific colors
  button: {
    background: ButtonBackgroundTokens;
    text: TextTokens;
  };
  // input specific colors
  input: {
    border: InputBorderTokens;
  };
  // MUI Divider specific colors
  divider: DividerTokens;
};

/* ---- 
LIGHT THEME
----- */
const lightThemeTextTokens: TextTokens = {
  weak: colors.neutral[50],
  standard: colors.neutral[60],
  strong: colors.neutral[90],
  action: colors.neutral[10], // button text
  disabled: colors.neutral[50],
};

export const lightThemeTokens: ThemeTokens = {
  background: {
    weak: colors.neutral[0],
    standard: colors.neutral[10],
    strong: colors.neutral[20],
  },
  text: {
    ...lightThemeTextTokens,
  },
  sentiment: {
    text: {
      negative: colors.red[80],
      positive: colors.green[80],
      notice: colors.yellow[80],
      info: colors.blue[80],
      neutral: colors.neutral[60],
      primary: colors.orange[60],
    },
  },
  support: {
    negative: {
      weak: colors.red[10],
      standard: colors.red[20],
      strong: colors.red[70],
    },
    positive: {
      weak: colors.green[10],
      standard: colors.green[20],
      strong: colors.green[70],
    },
    notice: {
      weak: colors.yellow[10],
      standard: colors.yellow[20],
      strong: colors.yellow[70],
    },
    info: {
      weak: colors.blue[10],
      standard: colors.blue[20],
      strong: colors.blue[70],
    },
    neutral: {
      weak: colors.neutral[20],
      standard: colors.neutral[30],
      strong: colors.neutral[70],
    },
    primary: {
      weak: colors.orange[10],
      standard: colors.orange[20],
      strong: colors.orange[70],
    },
  },
  button: {
    background: {
      primary: {
        standard: colors.orange[60],
        hover: colors.orange[70],
        pressed: colors.orange[60],
        disabled: colors.neutral[20],
      },
      secondary: {
        standard: colors.neutral[60],
        hover: colors.neutral[70],
        pressed: colors.neutral[50],
        disabled: colors.neutral[20],
      },
      tertiary: {
        standard: colors.neutral[30],
        hover: colors.neutral[40],
        pressed: colors.neutral[60],
        disabled: colors.neutral[40],
      },
      danger: {
        standard: colors.red[60],
        hover: colors.red[80],
        pressed: '#4F0101',
        disabled: colors.neutral[20],
      },
    },
    text: { ...lightThemeTextTokens },
  },
  input: {
    border: {
      standard: colors.neutral[30],
      hover: colors.neutral[40],
      active: colors.orange[60],
      focus: colors.neutral[60],
      disabled: colors.neutral[40],
    },
  },
  divider: {
    weak: colors.neutral[10],
    standard: colors.neutral[20],
    strong: colors.neutral[30],
  },
};

/* ----- 
DARK THEME
----- */
const darkThemeTextTokens: TextTokens = {
  weak: colors.neutral[50],
  standard: colors.neutral[40],
  strong: colors.neutral[10],
  action: colors.neutral[10], // button text
  disabled: colors.neutral[50],
};

export const darkThemeTokens: ThemeTokens = {
  background: {
    weak: colors.neutral[80],
    standard: colors.neutral[90],
    strong: colors.neutral[100],
  },
  text: {
    ...darkThemeTextTokens,
  },
  sentiment: {
    text: {
      negative: colors.red[40],
      positive: colors.green[40],
      notice: colors.yellow[40],
      info: colors.blue[40],
      neutral: colors.neutral[40],
      primary: colors.orange[70],
    },
  },
  support: {
    negative: {
      weak: colors.red[100],
      standard: colors.red[90],
      strong: colors.red[40],
    },
    positive: {
      weak: colors.green[100],
      standard: colors.green[90],
      strong: colors.green[40],
    },
    notice: {
      weak: colors.yellow[100],
      standard: colors.yellow[90],
      strong: colors.yellow[40],
    },
    info: {
      weak: colors.blue[100],
      standard: colors.blue[90],
      strong: colors.blue[40],
    },
    neutral: {
      weak: colors.neutral[80],
      standard: colors.neutral[70],
      strong: colors.neutral[30],
    },
    primary: {
      weak: colors.orange[100],
      standard: colors.orange[90],
      strong: colors.orange[60],
    },
  },
  button: {
    background: {
      primary: {
        standard: colors.orange[60],
        hover: colors.orange[70],
        pressed: colors.orange[60],
        disabled: colors.neutral[20],
      },
      secondary: {
        standard: colors.neutral[60],
        hover: colors.neutral[70],
        pressed: colors.neutral[50],
        disabled: colors.neutral[20],
      },
      tertiary: {
        standard: colors.neutral[30],
        hover: colors.neutral[40],
        pressed: colors.neutral[60],
        disabled: colors.neutral[40],
      },
      danger: {
        standard: colors.red[60],
        hover: colors.red[80],
        pressed: '#4F0101',
        disabled: colors.neutral[20],
      },
    },
    text: {
      ...darkThemeTextTokens,
    },
  },
  input: {
    border: {
      standard: colors.neutral[30],
      hover: colors.neutral[40],
      active: colors.orange[60],
      focus: colors.neutral[60],
      disabled: colors.neutral[70],
    },
  },
  divider: {
    weak: colors.neutral[70],
    standard: colors.neutral[60],
    strong: colors.neutral[50],
  },
};
