import { FC, SetStateAction, useEffect, useState } from 'react';

// styles, assets
import { StyledRightBottomButton } from 'pages/Login/UpdatePassword/UpdatePassword.styles';
import { StyledLink } from 'pages/Login/Login.styles';

// components
import Notification from 'components/Notification';
import Input from 'components/Input';
import { Button } from 'components/Button/Button';
import { publicRoutesUrls } from 'router/constants';
import { useNavigate } from 'react-router-dom';
import { sendEmailForResetPassword } from 'utils/api/auth';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { Box, Stack, Typography } from '@mui/material';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Card } from 'components/Card/Card';

const validateEmail = (input: string) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(input);
};

export const EmailForResetLink: FC<{
  email?: string;
}> = ({ email }) => {
  const navigate = useNavigate();
  const [linkSent, setLinkSent] = useState(false);
  const [inputValue, setInputValue] = useState(email ?? '');
  const [isInputValid, setIsInputValid] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState<string>();

  const dispatch = useAppDispatch();

  const handleSendEmailForResetPassword = (email: string) => {
    sendEmailForResetPassword(email)
      .then((res) => {
        setLinkSent(true);
        setNotificationMessage(res.data.message);
      })
      .catch((error) => {
        const err = error as Error;
        dispatch(createNotification(errorNotification(err.message), err));
      });
  };

  const handleInputChange = (event: {
    target: { value: SetStateAction<string>; validity: { valid: boolean } };
  }) => {
    if (linkSent) setLinkSent(false);
    const currentValue = event.target.value as string;
    setIsInputValid(validateEmail(currentValue) && event.target.validity.valid);
    setInputValue(event.target.value);
  };

  const handleResendClick = () => {
    if (isInputValid) {
      handleSendEmailForResetPassword(inputValue);
    }
  };

  useEffect(() => {
    if (inputValue) {
      setIsInputValid(validateEmail(inputValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      noPadding
      header={
        <Box padding={2}>
          <Typography variant="subheadingMedium">Reset Password</Typography>
        </Box>
      }
      body={
        <Stack padding={2} gap={1}>
          <Typography variant="bodyMedium">
            Please enter your email address to receive a password reset link.
          </Typography>
          <Input
            label="Email"
            nodeLabel={linkSent ? <StyledLink>Resend link</StyledLink> : undefined}
            value={inputValue}
            placeholder="Email"
            onChange={handleInputChange}
            type="email"
          />
          {notificationMessage && (
            <Notification
              message={`We’ve sent an email to ${inputValue} .
            ${notificationMessage}`}
              onClose={() => setNotificationMessage('')}
              title="An email is on its way!"
              variant="info"
            />
          )}
        </Stack>
      }
      footer={
        <Box padding={2}>
          {linkSent ? (
            <MuiStyledModalFooterButtons>
              <Button variant="primary" fullWidth onClick={() => navigate(publicRoutesUrls.login)}>
                Back to Homepage
              </Button>
            </MuiStyledModalFooterButtons>
          ) : (
            <MuiStyledModalFooterButtons>
              <StyledRightBottomButton
                variant="primary"
                type="submit"
                onClick={handleResendClick}
                disabled={!isInputValid}
              >
                Reset password
              </StyledRightBottomButton>
            </MuiStyledModalFooterButtons>
          )}
        </Box>
      }
    />
  );
};
