import { USER_STATUS, UsersTabType, USER_TAB_STATUS } from 'utils/constants/users';

export const getUserStatus = (tabValue: UsersTabType): USER_STATUS => {
  switch (tabValue) {
    case USER_TAB_STATUS.ACTIVE:
      return USER_STATUS.ACTIVE;
    case USER_TAB_STATUS.INVITED:
      return USER_STATUS.PENDING;
    case USER_TAB_STATUS.INACTIVE:
      return USER_STATUS.DELETED;
    default:
      return USER_STATUS.ACTIVE;
  }
};
