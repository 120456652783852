import { Button } from 'components/Button/Button';
import Form from 'components/Form/Form';
import Input from 'components/Input';
import useAppDispatch from 'hooks/useAppDispatch';
import {
  StyledCheckboxWrapper,
  StyledContentWrapper,
  StyledLoginContainer,
  StyledLoginFormWrapper,
  StyledLoginLogoWrapper,
  StyledNeedHelp,
  StyledReviewTermsOfUse,
} from 'pages/Login/Login.styles';
import { Checkbox } from 'components/BooleanInputs';
import { FC } from 'react';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { SetPasswordFlows } from './SetPassword.constants';
import { authPasswordReset } from 'utils/api/auth';
import { contactOnyxSupport } from 'utils/externalLinks';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { privateWeakRoutesUrls, publicRoutesUrls } from 'router/constants';
import { resetPasswordSchemaUrl } from 'components/Form/formSchemas';
import { storeWeakToken } from 'utils/user/storage';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import FormGridItem from 'components/Form/components/FormGridItem';
import { Box, Typography } from '@mui/material';

const ResetPasswordPage: FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const authPasswordResetMutation = useMutation({
    mutationFn: (data: {
      actionUri: string;
      authPasswordResetValues: {
        password: string;
        passwordRepeat: string;
        termsAccepted: boolean;
      };
    }) => authPasswordReset(data.actionUri, data.authPasswordResetValues),
    onSuccess: (loginResponse) => {
      storeWeakToken(loginResponse.data.weakToken);
      if (loginResponse.data._actions['2fa']) {
        navigate(privateWeakRoutesUrls.twoFa, {
          state: loginResponse.data._actions['2fa'],
        });
      } else if (loginResponse.data._actions.request2fa) {
        navigate(privateWeakRoutesUrls.twoFAsetup, {
          state: loginResponse.data._actions.request2fa,
        });
      }
    },
    onError: (error: Error) =>
      dispatch(createNotification(errorNotification(error.message, error.name), error)),
  });

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    if (values.password !== values.passwordRepeat) {
      dispatch(createNotification(errorNotification('Passwords do not match.')));
      return;
    }

    const authPasswordResetValues = {
      password: values.password,
      passwordRepeat: values.passwordRepeat,
      termsAccepted: values.termsAccepted,
    };

    let actionUri;

    switch (state.passwordFlow) {
      case SetPasswordFlows.resetPasswordFlow:
        actionUri = state?.data._actions.updatePassword.uri;
        break;
      case SetPasswordFlows.verifyInvitationFlow:
        actionUri = state?.data._actions.addPassword.uri;
        break;
    }

    if (actionUri) {
      authPasswordResetMutation.mutate({ actionUri, authPasswordResetValues });
    }
  };

  return (
    <StyledLoginContainer>
      <StyledContentWrapper>
        <StyledLoginLogoWrapper>
          <Logo width={192} height={96} />
        </StyledLoginLogoWrapper>
        <StyledLoginFormWrapper>
          <Form
            schemaUrl={resetPasswordSchemaUrl}
            onSubmit={onSubmit}
            initialValues={{ email: state?.data?.email ?? '' }}
          >
            <FormGridContainer>
              <FormGridItem md={12}>
                <Typography variant="subheadingMedium">Set password</Typography>
              </FormGridItem>
              <FormGridItem md={12}>Welcome to Onyx! Please enter your credentials.</FormGridItem>
              <FormGridItem md={12}>
                <Input type="email" readOnly name={'email'} disabled />
              </FormGridItem>
              <FormGridItem md={12}>
                <Input name="password" inputType="password" />
              </FormGridItem>
              <FormGridItem md={12}>
                <Input name="passwordRepeat" inputType="password" />
              </FormGridItem>
              <FormGridItem md={12}>
                <StyledCheckboxWrapper>
                  <Checkbox
                    label={
                      <Box gap={1}>
                        I have reviewed and accept the
                        <StyledReviewTermsOfUse
                          onClick={(e) => {
                            navigate(publicRoutesUrls.termsOfUse);
                          }}
                        >
                          Terms of Use
                        </StyledReviewTermsOfUse>
                      </Box>
                    }
                    name="termsAccepted"
                  />
                </StyledCheckboxWrapper>
              </FormGridItem>
              <FormGridItem md={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                  disabled={authPasswordResetMutation.isLoading}
                  fullWidth
                  type="submit"
                  isLoading={authPasswordResetMutation.isLoading}
                  style={{ width: '50%' }}
                  variant="primary"
                >
                  Submit
                </Button>
              </FormGridItem>
            </FormGridContainer>
          </Form>
        </StyledLoginFormWrapper>
        <StyledNeedHelp cols={12}>
          Need Help?{' '}
          <a href={contactOnyxSupport} target="_blank" rel="noreferrer">
            Contact Onyx support.
          </a>
        </StyledNeedHelp>
      </StyledContentWrapper>
    </StyledLoginContainer>
  );
};

export default ResetPasswordPage;
