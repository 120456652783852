import { TableCell, TableRow } from 'components/Table';
import { EditableTableCell } from './EditableTableCell/EditableTableCell';
import { RebalanceDetails, RebalanceWithDetails } from 'utils/types/rebalance';
import { escapeNaN, formatAsPercentage, getColorForNumber } from 'utils/formatting';
import { StyledColoredTableCell } from 'pages/Rebalance/RebalancePage.styles';
import { decimalToPercentage, percentageToDecimal } from 'utils/numbers';
import Tag from 'components/Tag/Tag';
import BigNumber from 'bignumber.js';
import {
  CRYPTO_DECIMAL_PLACES,
  FIAT_DECIMAL_PLACES,
  PERCENTAGE_DECIMAL_PLACES,
} from 'utils/constants/numbers';

interface RebalanceDetailsTableRowProps {
  rebalanceDetails: RebalanceDetails;
  rebalance?: RebalanceWithDetails;
  initialRebalanceDetails?: RebalanceDetails;
  updateRow: (rebDet: RebalanceDetails) => void;
}

const isEditable = (value?: string | number) => {
  if (Number(value) === 0 || value === 'NaN' || value === undefined) return true;

  return false;
};

const getRawFeeBps = (rebalanceDetails: RebalanceDetails) => {
  return rebalanceDetails.action === 'BUY'
    ? rebalanceDetails?.buyFeesBps
    : rebalanceDetails.sellFeesBps;
};

const getFeeBps = (rebalanceDetails: RebalanceDetails) => {
  const feeBPS =
    rebalanceDetails.action === 'BUY' ? rebalanceDetails?.buyFeesBps : rebalanceDetails.sellFeesBps;
  if (feeBPS) return `${feeBPS} bps`;
  return '-';
};

const getRebalanceDetailsWithUpdatedFees = (rebalanceDetails: RebalanceDetails, val: string) => {
  return { ...rebalanceDetails, buyFeesBps: val, sellFeesBps: val };
};

export const RebalanceDetailsTableRow = ({
  rebalanceDetails,
  rebalance,
  initialRebalanceDetails,
  updateRow,
}: RebalanceDetailsTableRowProps) => {
  const assetRounding = rebalance?.roundings[rebalanceDetails.ticker] || CRYPTO_DECIMAL_PLACES;
  return (
    <TableRow data-qa-id={`${rebalanceDetails.ticker}-row`}>
      <TableCell
        data-qa-id={`${rebalanceDetails.ticker}-ticker`}
        hoverText={`${rebalanceDetails.ticker} values in coin appeared rounded to ${assetRounding} decimals`}
        enableOnHover
        fixedColumn
      >
        {rebalanceDetails.ticker}
      </TableCell>
      <TableCell
        data-qa-id={`${rebalanceDetails.preBalance}-name`}
        hoverText={rebalanceDetails.preBalance}
        enableOnHover
      >
        {escapeNaN(new BigNumber(rebalanceDetails.preBalance).toFixed(assetRounding))}
      </TableCell>
      <EditableTableCell
        data-qa-id={`${rebalanceDetails.price}-type`}
        value={rebalanceDetails.price}
        formatedValue={escapeNaN(new BigNumber(rebalanceDetails.price).toFixed(4)) as string}
        updateValue={(val) => {
          updateRow({ ...rebalanceDetails, price: val as string });
        }}
        editable={isEditable(initialRebalanceDetails?.price ?? rebalanceDetails?.price)}
        showFullValueOnHover
      />
      <TableCell
        data-qa-id={`${rebalanceDetails.preValue}-type`}
        hoverText={rebalanceDetails.preValue}
        enableOnHover
      >
        {escapeNaN(new BigNumber(rebalanceDetails.preValue).toFixed(FIAT_DECIMAL_PLACES))}
      </TableCell>
      <TableCell
        data-qa-id={`${rebalanceDetails.preWeight}-type`}
        hoverText={formatAsPercentage(rebalanceDetails.preWeight)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.preWeight, PERCENTAGE_DECIMAL_PLACES)}
      </TableCell>
      <EditableTableCell
        data-qa-id={`${rebalanceDetails.targetWeight}-type`}
        value={decimalToPercentage(rebalanceDetails.targetWeight)}
        updateValue={(val) =>
          updateRow({
            ...rebalanceDetails,
            targetWeight: percentageToDecimal(val as string),
          })
        }
        formatedValue={formatAsPercentage(rebalanceDetails.targetWeight, PERCENTAGE_DECIMAL_PLACES)}
        editable={isEditable(
          initialRebalanceDetails?.targetWeight ?? rebalanceDetails?.targetWeight
        )}
        showFullValueOnHover
      />
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.preDifference}-type`}
        color={getColorForNumber(rebalanceDetails.preDifference)}
        hoverText={formatAsPercentage(rebalanceDetails.preDifference)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.preDifference, PERCENTAGE_DECIMAL_PLACES)}
      </StyledColoredTableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.tradeCash}-type`}
        color={getColorForNumber(rebalanceDetails.tradeCash)}
        hoverText={rebalanceDetails.tradeCash}
        enableOnHover
      >
        {escapeNaN(new BigNumber(rebalanceDetails.tradeCash).toFixed(FIAT_DECIMAL_PLACES))}
      </StyledColoredTableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.tradeCoin}-type`}
        color={getColorForNumber(rebalanceDetails.tradeCoin)}
        hoverText={rebalanceDetails.tradeCoin}
        enableOnHover
      >
        {escapeNaN(new BigNumber(rebalanceDetails.tradeCoin).toFixed(assetRounding))}
      </StyledColoredTableCell>
      <TableCell
        data-qa-id={`${rebalanceDetails.postBalance}-type`}
        hoverText={rebalanceDetails.postBalance}
        enableOnHover
      >
        {escapeNaN(new BigNumber(rebalanceDetails.postBalance).toFixed(assetRounding))}
      </TableCell>
      <TableCell
        data-qa-id={`${rebalanceDetails.postValue}-type`}
        hoverText={rebalanceDetails.postValue}
        enableOnHover
      >
        {escapeNaN(new BigNumber(rebalanceDetails.postValue).toFixed(FIAT_DECIMAL_PLACES))}
      </TableCell>
      <TableCell
        data-qa-id={`${rebalanceDetails.postWeight}-type`}
        hoverText={formatAsPercentage(rebalanceDetails.postWeight)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.postWeight, PERCENTAGE_DECIMAL_PLACES)}
      </TableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.postDifference}-type`}
        color={getColorForNumber(rebalanceDetails.postDifference)}
        hoverText={formatAsPercentage(rebalanceDetails.postDifference)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.postDifference, PERCENTAGE_DECIMAL_PLACES)}
      </StyledColoredTableCell>
      <TableCell data-qa-id={`${rebalanceDetails.action}-type`}>
        <Tag
          label={rebalanceDetails.action}
          variant={rebalanceDetails.action === 'SELL' ? 'red' : 'green'}
        />
      </TableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.actualTrade}-type`}
        color={getColorForNumber(rebalanceDetails.actualTrade)}
        hoverText={rebalanceDetails.actualTrade}
        enableOnHover
      >
        {escapeNaN(new BigNumber(rebalanceDetails.actualTrade).toFixed(assetRounding))}
      </StyledColoredTableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.actualTradeCash}-type`}
        color={getColorForNumber(rebalanceDetails.actualTradeCash)}
        hoverText={rebalanceDetails.actualTradeCash}
        enableOnHover
      >
        {escapeNaN(new BigNumber(rebalanceDetails.actualTradeCash).toFixed(FIAT_DECIMAL_PLACES))}
      </StyledColoredTableCell>
      <TableCell
        data-qa-id={`${rebalanceDetails.actualBalance}-type`}
        hoverText={rebalanceDetails.actualBalance}
        enableOnHover
      >
        {escapeNaN(new BigNumber(rebalanceDetails.actualBalance).toFixed(assetRounding))}
      </TableCell>
      <TableCell
        data-qa-id={`${rebalanceDetails.actualPostValue}-type`}
        hoverText={rebalanceDetails.actualPostValue}
        enableOnHover
      >
        {escapeNaN(new BigNumber(rebalanceDetails.actualPostValue).toFixed(FIAT_DECIMAL_PLACES))}
      </TableCell>
      <TableCell
        data-qa-id={`${rebalanceDetails.actualPostWeight}-type`}
        hoverText={formatAsPercentage(rebalanceDetails.actualPostWeight)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.actualPostWeight, PERCENTAGE_DECIMAL_PLACES)}
      </TableCell>
      <StyledColoredTableCell
        data-qa-id={`${rebalanceDetails.actualPostWeightDifference}-type`}
        color={getColorForNumber(rebalanceDetails.actualPostWeightDifference)}
        hoverText={formatAsPercentage(rebalanceDetails.actualPostWeightDifference)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.actualPostWeightDifference, PERCENTAGE_DECIMAL_PLACES)}
      </StyledColoredTableCell>
      <TableCell data-qa-id={`rebalance-quoteCurrency`}>
        {rebalance?.product.quoteCurrency}
      </TableCell>
      <EditableTableCell
        data-qa-id="feeBPS"
        value={getRawFeeBps(rebalanceDetails) ?? 0}
        formatedValue={getFeeBps(rebalanceDetails)}
        updateValue={(val) => {
          updateRow(getRebalanceDetailsWithUpdatedFees(rebalanceDetails, val as string));
        }}
        editable
      />
    </TableRow>
  );
};
