import WalletsTable from 'shared/Tables/WalletsTable/WalletsTable';
import useAppModal from 'hooks/useAppModal';
import { CompanyCustodianWallet, CompanyOwnWallet, WalletType } from 'utils/types/wallets';
import { MODAL_ACTIONS, WALLET_MODAL } from 'shared/Modals/constants';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { Button } from 'components/Button/Button';
import { TitleArea } from 'components/TitleArea/TitleArea';
import {
  WALLETS_TABS,
  WALLETS_TABS_STATUS,
  WalletTabType,
  defaultWalletsFiltersData,
} from 'utils/constants/wallets';
import { getWalletsQuery } from 'utils/constants/reactQueries';
import { privateRoutesUrls } from 'router/constants';
import { queryClient } from 'utils/api/queries/queryClient';
import { useAuthenticatedUser, useUserPermissions } from 'store/user/selectors';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useUrlParams } from 'hooks/useUrlParams';
import { useWalletsQuery } from './hooks/useWalletsQuery';
import { Card } from 'components/Card/Card';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import { capitalize } from 'lodash';

function WalletsPage() {
  const { user } = useAuthenticatedUser();
  const openModal = useAppModal();
  const permissions = useUserPermissions();
  const { urlParams, setUrlParams, setUrlParamsWithDefaults } = useUrlParams(
    defaultWalletsFiltersData,
    privateRoutesUrls.dashboardRoutes.adminWallets
  );

  const { data, isLoading: loading } = useWalletsQuery(
    user?.organization?.companyId ?? '',
    'OWN',
    urlParams
  );
  const wallets = data?.data ?? [];
  const pagination = useBackendPagination(data ?? null, setUrlParams);

  const openWalletModal = (
    type: MODAL_ACTIONS,
    wallet?: CompanyOwnWallet | CompanyCustodianWallet
  ) => {
    openModal(
      {
        modalName: WALLET_MODAL,
        modalData: {
          data: { wallet, type: WalletType.OWN_WALLET },
          companyData: {
            id: user?.organization?.companyId,
            name: user?.organization?.name,
          },
          type: type,
          custom: {
            isToken: user?.organization.type === 'AUTHORIZED_MERCHANT',
          },
        },
      },
      {
        onCloseModalAction: () => queryClient.invalidateQueries({ queryKey: [getWalletsQuery] }),
      }
    );
  };
  const canEditWallet = permissions?.isAuthorizedParticipant || permissions?.isAuthorizedMerchant;

  const createNewWallet = canEditWallet && (
    <Button
      data-qa-id="newWalletButton"
      variant="primary"
      size="medium"
      onClick={() => openWalletModal(MODAL_ACTIONS.CREATE)}
      endIcon={<PlusIcon />}
    >
      New Wallet
    </Button>
  );

  const tabs = WALLETS_TABS.map((tab) => ({ name: tab[0], param: tab[1] }));

  return (
    <>
      <TitleArea
        title={'Wallets'}
        actionButton={createNewWallet}
        tabConfig={{
          tabs: tabs,
          setUrlParamsWithDefaults: (params) =>
            setUrlParamsWithDefaults({ ...params, status: params.tab }),
          active: urlParams.tab as WalletTabType,
        }}
      />
      <Card
        header={
          <TableTitleWithActions
            title={`${capitalize(
              String(
                urlParams?.tab === 'ACTIVE'
                  ? WALLETS_TABS_STATUS.ACTIVE
                  : WALLETS_TABS_STATUS.DEACTIVATED
              )
            )} Wallets`}
          />
        }
        headerDivider={false}
        body={
          <WalletsTable
            urlParams={urlParams}
            setUrlParams={setUrlParams}
            tableProperties={{
              pagination: pagination,
              noPadding: true,
            }}
            deleteAction={(wallet) => openWalletModal(MODAL_ACTIONS.DELETE, wallet)}
            editAction={(wallet) => openWalletModal(MODAL_ACTIONS.EDIT, wallet)}
            activateAction={(wallet) => openWalletModal(MODAL_ACTIONS.CUSTOM, wallet)}
            loadingData={loading}
            wallets={wallets ?? []}
          />
        }
      />
    </>
  );
}

export default WalletsPage;
