import { Box, useTheme } from '@mui/material';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { Button } from 'components/Button/Button';
import { ReactComponent as CloseIcon } from 'assets/close.svg';

interface ActionButtonsWrapperProps {
  onConfirm: () => void;
  onDecline: () => void;
}

export const ActionButtonsWrapper = ({
  onConfirm,
  onDecline,
  ...props
}: ActionButtonsWrapperProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        svg: {
          cursor: 'pointer',
          overflow: 'unset',
          height: '20px',
          width: '20px',
          paddingLeft: '2px',
        },
      }}
    >
      <Button
        variant="tertiary"
        style={{ padding: theme.spacing(1) }}
        size="small"
        onClick={onConfirm}
        startIcon={<CheckIcon fontSize={16} color="#2E9E48" />}
      />
      <Button
        variant="tertiary"
        style={{ padding: theme.spacing(1) }}
        size="small"
        onClick={onDecline}
        startIcon={<CloseIcon fontSize={16} color={'#CC0000'} />}
      />
    </Box>
  );
};
