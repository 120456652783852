import { TableRow } from 'components/Table';
import { RebalanceDetails, RebalanceWithDetails } from 'utils/types/rebalance';
import { escapeNaN, formatAsPercentage } from 'utils/formatting';
import { TotalTableCell } from 'pages/Rebalance/RebalanceDetailsPage/RebalanceDetailsPage.styles';
import {
  PERCENTAGE_DECIMAL_PLACES,
  FIAT_DECIMAL_PLACES,
  ACTUAL_POST_WEIGHT_DIFFERENCE_PERCENTAGE_DECIMALS,
} from 'utils/constants/numbers';
import BigNumber from 'bignumber.js';

interface RebalanceDetailsTableRowProps {
  rebalanceDetails: Partial<RebalanceDetails>;
  rebalance?: RebalanceWithDetails;
}

export const RebalanceDetailsTotalsTableRow = ({
  rebalanceDetails,
}: RebalanceDetailsTableRowProps) => {
  return (
    <TableRow data-qa-id={`total-row`}>
      <TotalTableCell data-qa-id={`total`} fixedColumn>
        Total
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.preBalance}-name`}>{'-'}</TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.price}-type`} align="right">
        {'-'}
      </TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.preValue}-type`}
        hoverText={rebalanceDetails.preValue}
        enableOnHover
      >
        {rebalanceDetails.preValue &&
          escapeNaN(new BigNumber(rebalanceDetails.preValue).toFixed(FIAT_DECIMAL_PLACES))}
      </TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.preWeight}-type`}
        hoverText={formatAsPercentage(rebalanceDetails.preWeight)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.preWeight, PERCENTAGE_DECIMAL_PLACES)}
      </TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.targetWeight}-type`}
        align="right"
        hoverText={formatAsPercentage(rebalanceDetails.targetWeight)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.targetWeight, PERCENTAGE_DECIMAL_PLACES)}
      </TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.preDifference}-type`}
        hoverText={formatAsPercentage(rebalanceDetails.preDifference)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.preDifference, PERCENTAGE_DECIMAL_PLACES)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.tradeCash}-type`}>{'-'}</TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.tradeCoin}-type`}>{'-'}</TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.postBalance}-type`}>{'-'}</TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.postValue}-type`}
        hoverText={rebalanceDetails.postValue}
        enableOnHover
      >
        {rebalanceDetails.postValue
          ? escapeNaN(new BigNumber(rebalanceDetails.postValue).toFixed(FIAT_DECIMAL_PLACES))
          : '-'}
      </TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.postWeight}-type`}
        hoverText={formatAsPercentage(rebalanceDetails.postWeight)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.postWeight, PERCENTAGE_DECIMAL_PLACES)}
      </TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.postDifference}-type`}
        hoverText={formatAsPercentage(rebalanceDetails.postDifference)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.postDifference, PERCENTAGE_DECIMAL_PLACES)}
      </TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.action}-type`}>-</TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.actualTrade}-type`}>{'-'}</TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.actualTradeCash}-type`}>{'-'}</TotalTableCell>
      <TotalTableCell data-qa-id={`${rebalanceDetails.actualBalance}-type`}>{'-'}</TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.actualPostValue}-type`}
        hoverText={rebalanceDetails.actualPostValue}
        enableOnHover
      >
        {rebalanceDetails.actualPostValue
          ? escapeNaN(new BigNumber(rebalanceDetails.actualPostValue).toFixed(FIAT_DECIMAL_PLACES))
          : '-'}
      </TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.actualPostWeight}-type`}
        hoverText={formatAsPercentage(rebalanceDetails.actualPostWeight)}
        enableOnHover
      >
        {formatAsPercentage(rebalanceDetails.actualPostWeight, PERCENTAGE_DECIMAL_PLACES)}
      </TotalTableCell>
      <TotalTableCell
        data-qa-id={`${rebalanceDetails.actualPostWeightDifference}-type`}
        hoverText={rebalanceDetails.actualPostWeightDifference}
        enableOnHover
      >
        {formatAsPercentage(
          rebalanceDetails.actualPostWeightDifference,
          ACTUAL_POST_WEIGHT_DIFFERENCE_PERCENTAGE_DECIMALS
        )}
      </TotalTableCell>
    </TableRow>
  );
};
