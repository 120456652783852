import { Box, Stack, Typography } from '@mui/material';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import Product from 'components/Product';
import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { CustomModal } from 'shared/Modals/Modal';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { IModalWithData } from 'shared/Modals/types';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { UnrealizedAccruedFee, WithdrawUnrealizedAccruedFee } from 'utils/types/fees';
import { useMutation } from 'react-query';
import { withdrawFees } from 'utils/api/fees';
import { createNotification } from 'store/notifications/actions';
import useAppDispatch from 'hooks/useAppDispatch';
import { useState } from 'react';
import InputComponent from 'components/Input';
import { map } from 'lodash';

export const FeesWithdrawBulkFeesModal = ({
  closeModal,
  data,
  onCloseModalAction,
}: IModalWithData) => {
  const uafs = data.data as UnrealizedAccruedFee[];
  const dispatch = useAppDispatch();
  const [selectedRows, setSelectedRows] = useState<WithdrawUnrealizedAccruedFee[]>(
    uafs.map((uaf) => ({
      productId: uaf.product._id,
      constituentTicker: uaf.constituentAsset.ticker,
    }))
  );

  const withdrawMutation = useMutation({
    mutationFn: () => {
      return withdrawFees(selectedRows);
    },
    onSuccess: (res) => {
      dispatch(
        createNotification({
          message: `Unrealized Accrued Fees withdrawn successfully`,
          title: 'Unrealized Accrued Fees withdrawal',
          type: 'success',
        })
      );
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(
        createNotification({
          message: error.message,
          title: 'Unrealized Accrued Fees withdrawal error',
          type: 'error',
        })
      );
    },
  });

  const submitHandler = () => {
    withdrawMutation.mutate();
    closeModal();
  };

  const Footer = (
    <Box padding={2}>
      <MuiStyledModalFooterButtons>
        <Button variant="secondary" onClick={closeModal} sx={{ width: 'auto' }}>
          Cancel
        </Button>
        <Button onClick={submitHandler} sx={{ width: 'auto' }} variant={'primary'}>
          Confirm & Withdraw
        </Button>
      </MuiStyledModalFooterButtons>
    </Box>
  );

  const isBulkWithdraw = uafs.length > 1;

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        sx={{ width: '560px' }}
        header={
          <Box display={'flex'} flexDirection={'column'} p={2}>
            <Typography variant="captionTinyStrong">{'WITHDRAW'}</Typography>
            <Typography variant="subheadingMedium" sx={{ wordBreak: 'break-word' }}>
              {isBulkWithdraw
                ? 'Bulk Fees Withdrawal'
                : `${uafs[0].product.ticker} / ${uafs[0].constituentAsset.ticker} Fees`}
            </Typography>
          </Box>
        }
        noPadding
        body={
          <Box display={'flex'} flexDirection={'column'} p={2} gap={2}>
            <Typography variant="labelMedium" fontWeight={400}>
              {'6 products where selected for bulk withdrawal. Please review and confirm:'}
            </Typography>
            <Table noPadding>
              <TableHeaderWithMultiSort
                columns={[
                  {
                    label: 'Product',
                    propName: 'roduct',
                    propType: 'string',
                    width: '30%',
                    sort: false,
                  },
                  {
                    label: 'Constituent',
                    propName: 'constituent',
                    propType: 'string',
                    width: '30%',
                    sort: false,
                  },
                  {
                    label: 'Amount',
                    propName: 'amount',
                    propType: 'string',
                    width: '40%',
                    sort: false,
                  },
                ]}
              />
              <TableBodyWithStates loadingData={Boolean(false)} hasContent={true}>
                {uafs?.map((fee) => {
                  const amount = selectedRows.find(
                    (row) =>
                      row.productId === fee.product._id &&
                      row.constituentTicker === fee.constituentAsset.ticker
                  )?.amount;
                  return (
                    <TableRow>
                      <TableCell nowrap="true">
                        <Stack direction="row" spacing={2}>
                          <Product instrument={fee.product} />
                        </Stack>
                      </TableCell>
                      <TableCell nowrap="true">
                        <Typography>{fee.constituentAsset.ticker}</Typography>
                      </TableCell>
                      <TableCell nowrap="true">
                        <Typography>
                          <InputComponent
                            value={amount ?? fee.totalUnrealizedAccruedFee}
                            onChange={(event) => {
                              setSelectedRows((prevState) =>
                                map(prevState, (row) =>
                                  row.productId === fee.product._id &&
                                  row.constituentTicker === fee.constituentAsset.ticker
                                    ? { ...row, amount: event.target.value }
                                    : row
                                )
                              );
                            }}
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBodyWithStates>
            </Table>
          </Box>
        }
        footer={Footer}
        onClose={closeModal}
      />
    </CustomModal>
  );
};
