import { capitalize } from 'lodash';
import { dateFormat } from 'shared/Tables/table.utils';
import { format, parseISO } from 'date-fns';
import { TableCell } from 'components/Table/TableCell';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { TableRow } from 'components/Table/TableRow';
import { PartnersUser } from 'utils/types';
import { isEmpty } from 'lodash';
import Tag from 'components/Tag/Tag';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

type Props = {
  user: PartnersUser;
  editAction: () => void;
  deleteAction: () => void;
  reactivateAction: () => void;
  resendInvitationAction: () => void;
  reset2FA: () => void;
  showActionsCell: boolean;
};

export const UsersTableRow = ({
  user,
  editAction,
  deleteAction,
  reactivateAction,
  resendInvitationAction,
  reset2FA,
  showActionsCell = true,
}: Props) => {
  return (
    <TableRow
      data-qa-id={`${user.email}-row`}
      key={user._id}
      {...(user?._actions?.update && { onClick: editAction })}
    >
      <TableCell data-qa-id={`${user.email}-name`}>
        {user.firstName} {user.lastName}{' '}
      </TableCell>
      <TableCell data-qa-id={`${user.email}-email`}>{user.email} </TableCell>
      <TableCell data-qa-id={`${user.email}-role`}>
        <Tag label={capitalize(user.role)} variant="yellow" />
      </TableCell>
      <TableCell data-qa-id={`${user.email}-created`}>
        {format(parseISO(user.createdAt), dateFormat)}
      </TableCell>
      <TableCell data-qa-id="actionBtn">
        {showActionsCell && !isEmpty(user._actions) && (
          <ButtonDropdown id={user._id ?? user.createdAt} className="actionsCell">
            {user?._actions?.update && (
              <ButtonDropdownItem id="editUser" label="Edit User" onClick={editAction} />
            )}
            {user?._actions?.resendInvitation && (
              <ButtonDropdownItem
                id="resendInvitation"
                label="Resend Invitation"
                onClick={resendInvitationAction}
              />
            )}
            {user?._actions?.reset2fa && (
              <ButtonDropdownItem id="reset2FA" label="Reset 2FA" onClick={reset2FA} />
            )}
            {user?._actions?.delete && (
              <ButtonDropdownItem id="deleteUser" label="Deactivate User" onClick={deleteAction} />
            )}
            {user?._actions?.reactivate && (
              <ButtonDropdownItem
                id="reactivateUser"
                label="Reactivate User"
                onClick={reactivateAction}
              />
            )}
          </ButtonDropdown>
        )}
      </TableCell>
    </TableRow>
  );
};
