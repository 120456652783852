import { privateRoutesUrls } from 'router/constants';
import { RebalanceDetails, RebalanceWithDetails } from 'utils/types/rebalance';
import { compileUrl } from 'utils/url';
import * as XLSX from 'xlsx';

export const compileUrlToRebalanceDetails = (productId?: string, date?: string) => {
  const detailsUrl = compileUrl(privateRoutesUrls.dashboardRoutes.rebalanceViewDetails, {
    label: 'productId',
    value: String(productId),
  });

  const url = compileUrl(detailsUrl, {
    label: 'date',
    value: String(date),
  });

  return url;
};

export const generateXlSXObject = (rebalance: RebalanceWithDetails) => {
  return {
    Ticker: rebalance.product.ticker,
    'Product Quote currency': rebalance.product.quoteCurrency,
    Date: rebalance.date,
    Provider: rebalance.provider,
    CostOfTrades: rebalance.costOfTrades,
    Turnover: rebalance.turnover,
    ...(rebalance.nextRebalanceDate && { NextRebalanceDate: rebalance.nextRebalanceDate }),
  };
};

const prepareIndexWeights = (details: RebalanceDetails) => {
  return {
    ticker: details.ticker,
    preWeight: details.preWeight,
    postWeight: details.postWeight,
    preDifference: details.preDifference,
    targetWeight: details.targetWeight,
    postDifference: details.postDifference,
  };
};

const prepareRebalanceDetails = (details: RebalanceDetails) => {
  return {
    ticker: details.ticker,
    action: details.action,
    feesBps: details.action === 'BUY' ? details.buyFeesBps : details.sellFeesBps,
    preBalance: details.preBalance,
    preValue: details.preValue,
    price: details.price,
    targetWeight: details.targetWeight,
    postBalance: details.postBalance,
    postValue: details.postValue,
    preDifference: details.preDifference,
    preWeight: details.preWeight,
    tradeCoin: details.tradeCoin,
    tradeCash: details.tradeCash,
    actualBalance: details.actualBalance,
    actualPostValue: details.actualPostValue,
    actualTrade: details.actualTrade,
    actualTradeCash: details.actualTradeCash,
    postDifference: details.postDifference,
    postWeight: details.postWeight,
    actualPostWeight: details.actualPostWeight,
    actualPostWeightDifference: details.actualPostWeightDifference,
  };
};

export const downloadXLS = (rebalance?: RebalanceWithDetails) => {
  if (rebalance) {
    const workbook = XLSX.utils.book_new();
    const infoWorksheet = XLSX.utils.json_to_sheet([generateXlSXObject(rebalance)]);

    XLSX.utils.book_append_sheet(workbook, infoWorksheet, 'Rebalance data');

    if (rebalance.details?.length) {
      const indexWeights = rebalance.details.map((details) => prepareIndexWeights(details));
      const indexWeightsWorksheet = XLSX.utils.json_to_sheet(indexWeights);
      XLSX.utils.book_append_sheet(workbook, indexWeightsWorksheet, 'Change in Index Weights');

      const xlsEntries = rebalance.details.map((details) => prepareRebalanceDetails(details));
      const entriesWorksheet = XLSX.utils.json_to_sheet(xlsEntries);
      XLSX.utils.book_append_sheet(workbook, entriesWorksheet, 'Rebalance details');
    }
    XLSX.writeFile(workbook, `${rebalance.product.ticker}-${rebalance.date}.xlsx`);
  }
};

export const downloadAllRebalancesXLS = (rebalances?: RebalanceWithDetails[], date?: string) => {
  if (!rebalances) return;

  const workbook = XLSX.utils.book_new();

  rebalances.forEach((rebalance) => {
    const xlsEntries = rebalance.details.map((details) => prepareRebalanceDetails(details));
    XLSX.utils.book_append_sheet(
      workbook,
      XLSX.utils.json_to_sheet(xlsEntries),
      `${rebalance.product.ticker}-${rebalance.date}`
    );
  });
  XLSX.writeFile(workbook, `Rebalances-${date}.xlsx`);
};
