import { Button } from 'components/Button/Button';
import { Box, styled as MuiStyled } from '@mui/material';
import { ReactComponent as ResetPasswordIcon } from 'assets/reset-password.svg';
import { Column } from 'components/Grid';

export const StyledUpdatePasswordContentWrapper = MuiStyled(Box)`
  width: 400px;
  margin: auto;
`;

export const StyledResetPasswordIcon = MuiStyled(ResetPasswordIcon)(({ theme }) => ({
  marginLeft: `-${theme.spacers['spacing-16']}`,
}));

export const StyledRightBottomButton = MuiStyled(Button)(({ theme }) => ({
  width: '50%',
  marginLeft: 'auto',
}));

export const StyledFooterWrapper = MuiStyled(Box)(({ theme }) => ({
  marginTop: theme.spacers['spacing-32'],
  display: 'flex',
  flex: 1,
  gap: '8px',
}));

export const StyledRepeatPasswordForm = MuiStyled('form')(({ theme }) => ({
  paddingBottom: 0,
  Container: {
    marginTop: theme.spacers['spacing-32'],
  },
  button: {
    marginTop: theme.spacers['spacing-32'],
  },
}));

export const StyledInputWrapper = MuiStyled(Box)(({ theme }) => ({
  marginTop: theme.spacers['spacing-32'],
}));

export const StyledNoInlinePaddingColumn = MuiStyled(Column)(({ theme }) => ({
  paddingInline: 0,
  paddingBottom: 0,
}));
