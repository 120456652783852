import useAppDispatch from 'hooks/useAppDispatch';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useQuery } from 'react-query';
import { requestCompanyETPs, requestCompanyTokens } from 'utils/api/partners';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { getCompanyETPs, getCompanyTokens } from 'utils/constants/reactQueries';

export const useCompanyETPs = (companyId: string) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticatedUser();

  return useQuery([getCompanyTokens, user, companyId], async () => {
    try {
      const { data } = await requestCompanyETPs(companyId);
      return data;
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
    }
  });
};

export const useCompanyTokens = (companyId: string) => {
  const dispatch = useAppDispatch();
  const { user } = useAuthenticatedUser();

  return useQuery([getCompanyETPs, user, companyId], async () => {
    try {
      const { data } = await requestCompanyTokens(companyId);
      return data;
    } catch (err) {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
    }
  });
};
