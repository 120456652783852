import useAppDispatch from 'hooks/useAppDispatch';
import { OrderFormProps } from 'utils/types/orders';
import { OrderPageHeader } from 'pages/Orders/Orders/Order/components/OrderPageHeader';
import { SingleOrderView } from 'pages/Orders/Orders/Order/components/SingleOrderView';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useCreateOrder } from 'pages/Orders/Orders/Order/hooks/useCreateOrder';
import { useInstrumentsOrder } from 'hooks/useInstrumentsQuery';
import { useLoadOrderDetails } from 'pages/Orders/Orders/Order/hooks/useLoadOrderDetails';
import { useState } from 'react';

const CreateOrder = () => {
  const dispatch = useAppDispatch();
  const [updatedOrderData, setUpdatedOrderData] = useState<OrderFormProps>();
  const { user } = useAuthenticatedUser();
  const { instruments, isLoading: loadingInstruments } = useInstrumentsOrder();
  const instrument = instruments?.find((i) => i._id === updatedOrderData?.productId);
  const { data: order, isLoading: loading } = useLoadOrderDetails(updatedOrderData, instrument);

  const { createOrder } = useCreateOrder();

  const handleOnSubmit = async (orderFormProps: OrderFormProps) => {
    createOrder.mutate({
      ...orderFormProps,
      submit: order?.hasPortfolioCompositionApproved || order?.isSeed,
    });
  };

  const handleChangeOrder = async (orderFormData: OrderFormProps | undefined) => {
    if (orderFormData?.productId) {
      try {
        setUpdatedOrderData(orderFormData);
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message, 'Error'), error));
      }
    }
  };

  return (
    <>
      <TitleArea title={<OrderPageHeader productType="ETP" isNewOrder />} showBackButton={true} />
      <SingleOrderView
        productType="ETP"
        changeOrder={handleChangeOrder}
        isSubmitting={createOrder.isLoading}
        loading={loadingInstruments || loading}
        onSubmit={handleOnSubmit}
        order={order}
        orderFlow="create"
        user={user}
      />
    </>
  );
};

export default CreateOrder;
