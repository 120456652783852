export enum USER_STATUS {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
}

export const USER_TAB_STATUS = {
  ACTIVE: 'active',
  INVITED: 'invited',
  INACTIVE: 'inactive',
};

export enum USERS_STATUS_LABEL {
  ACTIVE = 'Active',
  PENDING = 'Invited',
  INACTIVE = 'Inactive',
}

export type UsersTabType =
  | typeof USER_TAB_STATUS.ACTIVE
  | typeof USER_TAB_STATUS.INVITED
  | typeof USER_TAB_STATUS.INACTIVE
  | undefined;
