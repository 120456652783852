import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { Calendar } from 'utils/types/calendar';
import { CustomModal } from 'shared/Modals/Modal';
import { IModalWithData } from 'shared/Modals/types';
import { apiUrls } from 'utils/constants/apiUrls';
import { calendarDeletedNotification } from 'shared/Notifications/calendars.notifications';
import { createNotification } from 'store/notifications/actions';
import { deleteEntityApiCall } from 'utils/api/crudActions';
import { useMutation } from 'react-query';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import { Box, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';

export const CalendarDeleteModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const calendar = data.data as Calendar;
  const { isAdmin } = data;
  const uriPath = isAdmin
    ? `${apiUrls.admin}${apiUrls.calendars}/id=${calendar._id}`
    : calendar._actions?.delete?.uri;

  const calendarDeleteMutation = useMutation({
    mutationFn: (uriPath: string) => {
      return deleteEntityApiCall<Calendar>(uriPath, 'Error while deleting calendar');
    },
    onSuccess: (res) => {
      dispatch(createNotification(calendarDeletedNotification.success(`${calendar.name}`)));
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err: Error) => {
      dispatch(createNotification(calendarDeletedNotification.error(err.message), err));
    },
  });

  const handleDelete = () => {
    uriPath && calendarDeleteMutation.mutate(uriPath);
  };

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={<CardHeaderTitleWithLabel label="Calendars" title="Delete Calendar" />}
        body={
          <Box padding={2}>
            <Typography variant="bodyMedium">
              Are you sure you want to permanently delete {calendar.name} as a calendar?
            </Typography>
          </Box>
        }
        onClose={closeModal}
        footer={
          <Box padding={2}>
            <MuiStyledModalFooterButtons>
              <Button
                data-qa-id="cancelButton"
                variant="secondary"
                onClick={closeModal}
                type="button"
              >
                Cancel
              </Button>
              <Button
                data-qa-id="deleteButton"
                variant="danger"
                onClick={handleDelete}
                type="submit"
                isLoading={calendarDeleteMutation.isLoading}
              >
                Delete
              </Button>
            </MuiStyledModalFooterButtons>
          </Box>
        }
      ></Card>
    </CustomModal>
  );
};
