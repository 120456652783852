import { Emphasis } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const dangerButton = (theme: Theme) => {
  const dangerButton = theme.customComponents.buttonPalette.danger;
  return [
    {
      props: (props: { variant: string; emphasis: Emphasis }) => props.variant === 'danger',
      style: {
        color: dangerButton.color,
        backgroundColor: dangerButton.backgroundColor,
        '&:hover': {
          backgroundColor: dangerButton.hover?.backgroundColor,
        },
        '&:active': {
          backgroundColor: dangerButton.active?.backgroundColor,
        },
        '&:focus-visible': {
          backgroundColor: dangerButton.focusVisible?.backgroundColor,
          boxShadow: dangerButton.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: dangerButton.disabled?.color,
          backgroundColor: dangerButton.disabled?.backgroundColor,
          '& svg': {
            fill: dangerButton.disabled?.color,
          },
        },
        span: { color: dangerButton.color },
        '& svg': {
          fill: dangerButton.color,
        },
      },
    },
  ];
};
