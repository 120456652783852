import useAppDispatch from 'hooks/useAppDispatch';
import { getPCF } from 'utils/api/pcfs';
import { loadProductData } from 'utils/api/instruments';
import { useQuery } from 'react-query';
import { Params } from 'react-router-dom';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';

export const usePCFDetailsQuery = (params: Params, options?: { enabled?: boolean }) => {
  const dispatch = useAppDispatch();

  return useQuery(
    ['pcf', params],
    async () => {
      try {
        const product = await loadProductData(params.productId!);
        const pcf = await getPCF(params.productId!, params.valuationDate!).then(({ data }) => data);
        return { ...pcf, constituentAssets: product.constituentAssets };
      } catch (err) {
        const error = err as Error;
        dispatch(createNotification(errorNotification(error.message ?? ''), error));
        throw error; // Ensure the error propagates for React Query to handle
      }
    },
    {
      retry: 2,
      enabled: options?.enabled ?? true,
    }
  );
};
