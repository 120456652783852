import { Emphasis } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const secondaryButton = (theme: Theme) => {
  const secondaryButton = theme.customComponents.buttonPalette.secondary;
  return [
    {
      props: (props: { variant: string; emphasis: Emphasis }) => props.variant === 'secondary',
      style: {
        color: secondaryButton.color,
        backgroundColor: secondaryButton.backgroundColor,
        '&:hover': {
          backgroundColor: secondaryButton.hover?.backgroundColor,
        },
        '&:active': {
          backgroundColor: secondaryButton.active?.backgroundColor,
        },
        '&:focus-visible': {
          boxShadow: secondaryButton.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: secondaryButton.disabled?.color,
          backgroundColor: secondaryButton.disabled?.backgroundColor,
          '& svg': {
            fill: secondaryButton.disabled?.color,
          },
        },
        span: { color: secondaryButton.color },
        '& svg': {
          fill: secondaryButton.color,
        },
      },
    },
  ];
};
