import { Deliverable } from 'utils/types';
import Notification from 'components/Notification';
import Table from 'components/Table';
import Input from 'components/Input';
import BigNumber from 'bignumber.js';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { TableRow } from 'components/Table/TableRow';
import { TableCell } from 'components/Table/TableCell';
import { TableBody } from '@mui/material';
import { sortBy } from 'lodash';

export const ExpectedSeedDeliverablesTableColumnsConfig = [
  { label: 'Ticker', propName: 'ticker', propType: 'string', width: '38%' },
  { label: 'Quantity', propName: 'quantity', propType: 'string', width: '16%' },
];
export interface SeedDeliverablesInterface {
  onChangeSeedDeliverable?: (deliverables: Deliverable) => void;
  seedDeliverables?: Deliverable[];
}

const ExpectedSeedDeliverablesTable = ({
  onChangeSeedDeliverable,
  seedDeliverables,
}: SeedDeliverablesInterface) => {
  return (
    <>
      <Notification
        messageMaxWidth
        message="Please fill out the deliverable amount for a seed order"
        multiline={'false'}
        title="No PCF found"
        variant="warning"
        withcountdown="false"
      />
      <Table noPadding={true}>
        <TableHeaderWithMultiSort columns={ExpectedSeedDeliverablesTableColumnsConfig} />
        <TableBody>
          {sortBy(seedDeliverables, 'ticker')?.map((deliverable) => (
            <TableRow key={deliverable.ticker}>
              <TableCell>{deliverable.ticker}</TableCell>
              <TableCell align="right">
                <Input
                  type="number"
                  step="any"
                  onChange={(event) => {
                    onChangeSeedDeliverable &&
                      onChangeSeedDeliverable({
                        amount: event.target.value
                          ? new BigNumber(event.target.value ?? '').toNumber()
                          : undefined,
                        ticker: deliverable.ticker,
                      });
                  }}
                  value={deliverable.amount ?? ''}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ExpectedSeedDeliverablesTable;
