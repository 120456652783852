import useAppModal from 'hooks/useAppModal';
import { CALENDAR_MODAL, CALENDAR_EVENT_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { Calendar, CalendarEvent } from 'utils/types/calendar';
import { CalendarsTable } from 'shared/Tables/CalendarsTable/CalendarsTable';
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'shared/Tables/table.utils';
import { ParamsType } from 'hooks/useUrlParams';
import { StyledTableWrapper } from 'pages/Partners/components/PartnerRelatedEntitiesTabs/PartnerTabs.styles';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
import { getCalendarsQuery } from 'utils/constants/reactQueries';
import { queryClient } from 'utils/api/queries/queryClient';
import { useUserPermissions } from 'store/user/selectors';
import { useBackendPagination } from 'hooks/useBackendPagination';
import { useCalendarsQuery } from 'pages/Admin/CalendarsPage/hooks/useCalendars';
import { useCompanyQuery } from 'pages/Partners/hooks/useCompanyQuery';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';
import { Button } from 'components/Button/Button';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';

export const CalendarsStep = () => {
  const openModal = useAppModal();
  const permissions = useUserPermissions();
  const { id: partnerId } = useParams();
  const { data: partnerData } = useCompanyQuery(partnerId ?? '');
  const companyID = partnerData?._id ?? '';
  const [urlParams, setUrlParams] = useState<ParamsType>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_ITEMS_PER_PAGE,
  });
  const { data, isLoading: loading } = useCalendarsQuery(companyID, false, urlParams);
  const calendars = data?.calendars;
  const paginationResponse = data?.pagination ?? null;

  const pagination = useBackendPagination(paginationResponse, setUrlParams);
  const canAddCalendar = permissions?.canEditCompanyCalendar && partnerData?._actions?.addCalendar;

  const openCalendarModal = (type: MODAL_ACTIONS, calendarToEdit?: Calendar) => {
    openModal(
      {
        modalName: CALENDAR_MODAL,
        modalData: {
          data: calendarToEdit,
          companyData: {
            id: companyID,
            name: partnerData?.name,
          },
          type: type,
        },
      },
      {
        onCloseModalAction: () => queryClient.invalidateQueries({ queryKey: [getCalendarsQuery] }),
      }
    );
  };

  const openCalendarEventModal = (
    type: MODAL_ACTIONS,
    calendarEventCalendar: Calendar,
    calendarEventToDelete?: CalendarEvent
  ) => {
    openModal(
      {
        modalName: CALENDAR_EVENT_MODAL,
        modalData: {
          data: calendarEventToDelete,
          calendarData: calendarEventCalendar,
          companyData: {
            id: companyID,
            name: partnerData?.name,
          },
          type: type,
        },
      },
      {
        onCloseModalAction: () => queryClient.invalidateQueries({ queryKey: [getCalendarsQuery] }),
      }
    );
  };

  const tableToolbarTopButton = canAddCalendar && (
    <ButtonDropdown
      id={'add-calendar'}
      anchor={
        <Button variant="secondary" size="medium" endIcon={<PlusIcon />}>
          Add Calendar
        </Button>
      }
    >
      <ButtonDropdownItem
        id="addExistingCalendar"
        label="Add existing holiday calendar"
        onClick={() => openCalendarModal(MODAL_ACTIONS.CUSTOM)}
      />
      <ButtonDropdownItem
        id="addNewCalendar"
        label="Create new holiday calendar"
        onClick={() => openCalendarModal(MODAL_ACTIONS.CREATE)}
      />
    </ButtonDropdown>
  );

  return (
    <StyledTableWrapper>
      <TableTitleWithActions title="Calendars" actions={[tableToolbarTopButton]} />
      <CalendarsTable
        urlParams={urlParams}
        setUrlParams={setUrlParams}
        tableProperties={{
          pagination,
        }}
        calendars={calendars ?? []}
        loadingData={loading}
        editAction={(calendar) => openCalendarModal(MODAL_ACTIONS.EDIT, calendar)}
        deleteAction={(calendar) => openCalendarModal(MODAL_ACTIONS.DELETE, calendar)}
        subitemEditAction={(calendar, calendarEvent) =>
          openCalendarEventModal(
            calendarEvent ? MODAL_ACTIONS.EDIT : MODAL_ACTIONS.CREATE,
            calendar,
            calendarEvent
          )
        }
        subitemDeleteAction={(calendar, calendarEvent) =>
          openCalendarEventModal(MODAL_ACTIONS.DELETE, calendar, calendarEvent)
        }
      />
    </StyledTableWrapper>
  );
};
