import { Button } from 'components/Button/Button';
import useAppDispatch from 'hooks/useAppDispatch';
import { IModalWithData } from 'shared/Modals/types';
import { PartnersUser } from 'utils/types';
import { SuccessRequest } from 'utils/types/auth';
import { createNotification } from 'store/notifications/actions';
import { createEntityApiCallWithCustomResponse } from 'utils/api/crudActions';
import { useMutation } from 'react-query';
import { userReactivatedNotifications } from 'shared/Notifications/users.notifications';
import { CustomModal } from 'shared/Modals/Modal';
import { MuiStyledModalFooterButtons } from 'shared/Modals/Modal.styles';
import CardHeaderTitleWithLabel from 'components/Card/CardHeaderTitleWithLabel';
import { Box, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';

export const ReactivateUserModal = ({ onCloseModalAction, closeModal, data }: IModalWithData) => {
  const dispatch = useAppDispatch();
  const user = data.data as PartnersUser;
  const usersName = `${user.firstName} ${user.lastName}`;

  const reactivateUserMutation = useMutation({
    mutationFn: () =>
      createEntityApiCallWithCustomResponse<string, SuccessRequest>(
        user?._actions?.reactivate?.uri!
      ),
    onSuccess: () => {
      dispatch(
        createNotification(
          userReactivatedNotifications.success(`${user.firstName} ${user.lastName}`)
        )
      );
      closeModal();
      onCloseModalAction && onCloseModalAction();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(userReactivatedNotifications.error(error.message), error));
    },
  });

  const handleReactivate = () => {
    reactivateUserMutation.mutate();
  };

  const Footer = (
    <MuiStyledModalFooterButtons>
      <Button
        variant="secondary"
        fullWidth
        onClick={closeModal}
        type="button"
        data-qa-id="cancelButton"
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        fullWidth
        onClick={handleReactivate}
        type="button"
        data-qa-id="reactivateButton"
      >
        Reactivate
      </Button>
    </MuiStyledModalFooterButtons>
  );

  return (
    <CustomModal open onCloseModal={closeModal}>
      <Card
        noPadding
        header={<CardHeaderTitleWithLabel label={'User'} title="Reactivate User" />}
        body={
          <Box padding={2}>
            <Typography variant="bodyMedium">
              Are you sure you want to reactivate <b>{usersName}</b>?
            </Typography>
          </Box>
        }
        footer={<Box padding={2}>{Footer}</Box>}
        onClose={closeModal}
      ></Card>
    </CustomModal>
  );
};
