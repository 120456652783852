import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import Table, { TableProps } from 'components/Table';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import isEmpty from 'lodash/isEmpty';
import { ParamsType } from 'hooks/useUrlParams';
import { PartnersUser } from 'utils/types';
import { UsersTableRow } from 'shared/Tables/UsersTable/UsersTableRow';
import { UsersTableColumnsConfig } from './tableColumnsConfig';

interface UsersTableProps {
  tableProperties: TableProps;
  editAction?: (user: PartnersUser) => void;
  deleteAction?: (user: PartnersUser) => void;
  reactivateAction?: (user: PartnersUser) => void;
  resendInvitationAction?: (user: PartnersUser) => void;
  reset2FA?: (user: PartnersUser) => void;
  users: PartnersUser[];
  loadingData?: boolean;
  setUrlParams: React.Dispatch<React.SetStateAction<ParamsType>>;
  urlParams: ParamsType;
}

const doesAnyUserHaveActions = (users: PartnersUser[]) => {
  return Boolean(users.find((user) => Object.keys(user._actions || {}).length > 0));
};

const UsersTable = ({
  tableProperties,
  editAction,
  deleteAction,
  reactivateAction,
  resendInvitationAction,
  reset2FA,
  users,
  loadingData,
  urlParams,
  setUrlParams,
}: UsersTableProps) => {
  const showActionsCell = doesAnyUserHaveActions(users);

  return (
    <Table {...tableProperties}>
      <TableHeaderWithMultiSort
        showActionsCell
        defaultSortedColumns={urlParams.sort ?? []}
        columns={UsersTableColumnsConfig}
        onColumnSort={(columns) => {
          setUrlParams((urlParams) => ({ ...urlParams, sort: columns }));
        }}
      />
      <TableBodyWithStates
        loadingData={Boolean(loadingData)}
        hasContent={!isEmpty(users)}
        noContentLabel="No users at the moment."
      >
        {users?.map((user) => (
          <UsersTableRow
            key={user._id}
            user={user}
            editAction={() => {
              if (editAction) editAction(user);
            }}
            deleteAction={() => {
              if (deleteAction) deleteAction(user);
            }}
            reactivateAction={() => {
              if (reactivateAction) reactivateAction(user);
            }}
            resendInvitationAction={() => {
              if (resendInvitationAction) resendInvitationAction(user);
            }}
            reset2FA={() => {
              if (reset2FA) reset2FA(user);
            }}
            showActionsCell={showActionsCell}
          />
        ))}
      </TableBodyWithStates>
    </Table>
  );
};

export default UsersTable;
