import SelectComponent from 'components/Select';
import { Box } from '@mui/material';
import { Button } from 'components/Button/Button';
import { Card } from 'components/Card/Card';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { TransactionType } from 'utils/types/nav';
import { UnrealizedAccruedFee, unrealizedAccruedFeeTransactionsTypes } from 'utils/types/fees';

interface FeeDetailsHeaderProps {
  fee: UnrealizedAccruedFee | undefined;
  onTypeChange: (type: TransactionType) => void;
  selectedType: TransactionType | undefined;
  downloadCSV: () => void;
}

export const FeesDetailsPageHeader = ({
  fee,
  onTypeChange,
  selectedType,
  downloadCSV,
}: FeeDetailsHeaderProps) => {
  return (
    <Card
      sx={{ padding: 2 }}
      body={
        <Box sx={{ display: 'flex', gap: 1 }}>
          {fee && (
            <Box sx={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
              <Box sx={{ width: 'auto' }}>
                <SelectComponent
                  className="product-icon"
                  value={selectedType}
                  size="medium"
                  placeholder="All Transactions"
                  options={unrealizedAccruedFeeTransactionsTypes}
                  onChange={(type) => onTypeChange(type as TransactionType)}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <Button
                  variant="tertiary"
                  emphasis="medium"
                  onClick={downloadCSV}
                  startIcon={<DownloadIcon />}
                >
                  {/*  */}
                  CSV
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      }
    />
  );
};
