import { Emphasis } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const primaryButton = (theme: Theme) => {
  const primaryButton = theme.customComponents.buttonPalette.primary;
  return [
    {
      props: (props: { variant: string; emphasis: Emphasis }) => props.variant === 'primary',
      style: {
        color: primaryButton.color,
        backgroundColor: primaryButton.backgroundColor,
        '&:hover': {
          backgroundColor: primaryButton.hover?.backgroundColor,
        },
        '&:active': {
          backgroundColor: primaryButton.active?.backgroundColor,
        },
        '&:focus-visible': {
          boxShadow: primaryButton.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: primaryButton.disabled?.color,
          backgroundColor: primaryButton.disabled?.backgroundColor,
          '& svg': {
            fill: primaryButton.disabled?.color,
          },
        },
        span: { color: primaryButton.color },
        '& svg': {
          fill: primaryButton.color,
        },
      },
    },
  ];
};
