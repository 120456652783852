import BigNumber from 'bignumber.js';
import { isNil } from 'lodash';

const CRYPTO_DECIMAL_PLACES = 8;

export const escapeNaN = (value?: number | string) => {
  if (value === 'NaN' || value === '-' || isNil(value)) return '-';
  else return Number(value).toLocaleString('en-US', { maximumFractionDigits: 20 });
};

export const getColorForNumber = (value: number | string) => {
  if (value === 'NaN' || value === '-' || Number(value) === 0) return 'main';

  if (Number(value) > 0) return 'success';

  return 'error';
};

export const formatAsPercentage = (value?: number | string, decimalPlaces?: number): string => {
  if (value === 'NaN' || value === '-' || isNil(value)) return '-';

  const bigNumberValue = new BigNumber(value);
  const multipliedValue = bigNumberValue.times(100);
  const formattedValue = multipliedValue.toFormat(decimalPlaces);
  return `${formattedValue}%`;
};

export const getCurrencySymbol = (currency?: string, locale = 'en-US') => {
  if (currency) {
    if (currency === 'CHF') {
      return '₣';
    }

    try {
      const symbol = new Intl.NumberFormat(locale, { style: 'currency', currency })
        ?.formatToParts(1)
        ?.find((x) => x?.type === 'currency')?.value;
      return symbol;
    } catch (error) {
      // default to currency if no symbol found
      return currency;
    }
  } else {
    return '';
  }
};

export const formatFiat = (currency: string, value: number, decimals?: number) => {
  const getDecimalPlaces = (value: number): number => {
    return value % 1 ? value.toString().split('.')[1].length : 0;
  };

  const formatCurrency = (currency: string, value: number, locale: string) => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: decimals ?? getDecimalPlaces(value),
      maximumFractionDigits: decimals ?? getDecimalPlaces(value),
    }).format(value);
  };

  const numberFormat = {
    USD: 'en-US',
    CHF: 'de-CH',
  };

  let displayValue: string;

  if (currency === 'CHF') {
    displayValue = formatCurrency('CHF', value, numberFormat['CHF']).replace('CHF', '₣');
  } else {
    displayValue = formatCurrency(currency, value, numberFormat['USD']);
  }

  return displayValue;
};

const FIAT_CURRENCIES = ['AUD', 'CHF', 'EUR', 'GBP', 'USD']; // supported fiat taken from BE

export const formatToCurrency = (amount?: number, currency?: string) => {
  if (amount && currency) {
    // fiat
    if (FIAT_CURRENCIES.includes(currency)) {
      return formatFiat(currency, amount);
      // crypto
    } else {
      return `${amount} ${currency}`;
    }
  } else {
    return '-';
  }
};

export const renderValueOrDash = (value: string | number | boolean | undefined) => {
  return Boolean(value) ? value : '-';
};

export const renderZeroValueOrDash = (value: string | number | boolean | undefined) => {
  return Boolean(value === 0 || value) ? value : '-';
};

export function formatCrypto(value?: number | string) {
  return formatDecimalValue(value, CRYPTO_DECIMAL_PLACES);
}

function formatDecimalValue(value: number | string | undefined, decimalPlaces: number) {
  return !value ? '' : new BigNumber(value).toFixed(decimalPlaces);
}

export const formatAddress = (dataArr: (string | undefined)[]) =>
  dataArr.reduce((acc, current) => {
    return acc ? acc + ', ' + current : current;
  }, '');

export function capitalizeFirstLetter(word: string) {
  if (!word) return word;
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
