import { PartnerType } from 'utils/types/partner';
import { ETPsStep } from './ETPsStep';
import { TokensStep } from './TokensStep';

export const ProductsStep = ({ companyType }: { companyType?: PartnerType }) => {
  if (companyType === PartnerType.AUTHORIZED_PARTICIPANT) {
    return <ETPsStep />;
  }

  if (companyType === PartnerType.AUTHORIZED_MERCHANT) {
    return <TokensStep />;
  }

  return <div></div>;
};
