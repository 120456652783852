import Table, { TableCell, TableRow } from 'components/Table';
import replace from 'lodash/replace';
import titelize from 'utils/decorators/titelize';
import { NavOptionsTitles } from 'pages/Orders/Orders/types';
import { Order, User, ProductType, OrderFlow } from 'utils/types';
import { Box, TableBody } from '@mui/material';
import { capitalizeFirstLetter } from 'utils/formatting';
import { orderStatusTagVariants } from 'utils/constants/orders';
import { renderOrderValue } from 'shared/Tables/table.utils';
import { variantTypes } from 'components/Tag/Tag.types';
import Tag from 'components/Tag/Tag';
import ExplorerLink from 'components/ExplorerLink';
import TableHeader from 'components/Table/TableHeader';
import { Card } from 'components/Card/Card';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';
import TransactionSummary from './TransactionSummary';
import { ReactComponent as TransactionSummaryIcon } from 'assets/transaction-summary.svg';

export interface DealInformationProps {
  productType: ProductType;
  order?: Order;
  user: User | null;
  orderFlow: OrderFlow;
}

const ZERO_UNITS = 0;

export const dealInformationColumnsConfig = [
  { label: 'Deal Information', propName: 'dealInformation', propType: 'string', width: '50%' },
];

const DealInformation = ({ productType, order, user, orderFlow }: DealInformationProps) => {
  const showTransactionSummary = orderFlow === 'confirm';
  const showOrderSummary =
    order?.deliveryType === 'CASH' && (order?.status === 'READY' || order?.status === 'SETTLED');

  return (
    <>
      {productType === 'ETP' && (
        <Box
          style={{
            gridTemplateColumns: showTransactionSummary ? '1fr minmax(0, 640px)' : '1fr',
          }}
          display="grid"
          gap={3}
        >
          <Table noPadding={true}>
            <TableHeader columns={dealInformationColumnsConfig} />
            <TableBody>
              <TableRow>
                <TableCell>Order Number</TableCell>
                <TableCell align="right">{order?.externalId || '-'}</TableCell>
              </TableRow>
              {orderFlow !== 'create' && (
                <TableRow data-html2canvas-ignore>
                  <TableCell>State</TableCell>
                  <TableCell align="right">
                    {order?.status ? (
                      <Tag
                        label={replace(titelize(order?.status), 'Ap ', 'AP ')}
                        variant={orderStatusTagVariants[order.status] as variantTypes}
                      />
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Deal Date</TableCell>
                <TableCell align="right">
                  {renderOrderValue(Boolean(order?.dealDate), order?.dealDate)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Settlement Date</TableCell>
                <TableCell align="right">
                  {order?.settlement
                    ? renderOrderValue(Boolean(order?.settlement.date), order?.settlement.date)
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell align="right">
                  {order?.type ? capitalizeFirstLetter(order?.type) : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Delivery Type</TableCell>
                <TableCell align="right">
                  {order?.deliveryType ? order?.deliveryType?.replaceAll('_', '-') : '-'}
                </TableCell>
              </TableRow>
              {order?.deliveryType === 'CASH' && (
                <>
                  <TableRow>
                    <TableCell>Order Type</TableCell>
                    <TableCell align="right">
                      {order?.pricingType ? NavOptionsTitles[order?.pricingType] : '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Delivery Currency</TableCell>
                    <TableCell align="right">{order?.deliveryCurrency || '-'}</TableCell>
                  </TableRow>
                </>
              )}
              <TableRow>
                <TableCell>Authorised Participant</TableCell>
                <TableCell align="right">
                  {renderOrderValue(
                    Boolean(order?.createdBy?.companyName),
                    order?.createdBy.onBehalfOf
                      ? order.createdBy.onBehalfOf?.companyName
                      : order?.createdBy?.companyName
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>User Email</TableCell>
                <TableCell align="right">
                  {renderOrderValue(
                    Boolean(order?.createdBy.email ? order.createdBy.email : user?.contact.email),
                    order?.createdBy.email ? order.createdBy?.email : user?.contact.email
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>AP Code</TableCell>
                <TableCell align="right">
                  {renderOrderValue(
                    Boolean(order?.authorizedParticipantCode),
                    order?.authorizedParticipantCode
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Number of Securities</TableCell>
                <TableCell align="right">
                  {renderOrderValue(Boolean(order), order?.numberOfSecurities || ZERO_UNITS)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Number of Creation Units</TableCell>
                <TableCell align="right">
                  {renderOrderValue(Boolean(order), order?.numberOfUnits || ZERO_UNITS)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Settlement Type</TableCell>
                <TableCell align="right">
                  {order?.settlement
                    ? renderOrderValue(Boolean(order), order?.settlement.type)
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Standard Settlement Horizon</TableCell>
                <TableCell align="right">
                  {order?.settlement
                    ? renderOrderValue(
                        Boolean(order?.settlement.horizon),
                        `${order?.settlement.horizon}`
                      )
                    : '-'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {/* Transaction Summary */}
          {showTransactionSummary && (
            <Card
              sx={{ padding: '24px' }}
              header={
                <TableTitleWithActions
                  icon={<TransactionSummaryIcon />}
                  title="Transaction Summary"
                />
              }
              headerDivider={false}
              body={<TransactionSummary order={order} showOrderSummary={showOrderSummary} />}
              maxCardHeight={showOrderSummary ? `520px` : '210px'}
              boxShadow={false}
            />
          )}
        </Box>
      )}
      {productType === 'Token' && (
        <Table noPadding={true}>
          <TableHeader columns={dealInformationColumnsConfig} />
          <TableBody>
            <TableRow>
              <TableCell>Order Number</TableCell>
              <TableCell align="right">{order?.externalId || '-'}</TableCell>
            </TableRow>
            <TableRow data-html2canvas-ignore>
              <TableCell>State</TableCell>
              <TableCell align="right">
                {order?.status ? (
                  <Tag
                    label={replace(titelize(order.status), 'Ap ', 'AP ')}
                    variant={orderStatusTagVariants[order.status] as variantTypes}
                  />
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Deal Date</TableCell>
              <TableCell align="right">
                {renderOrderValue(Boolean(order?.dealDate), order?.dealDate)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Settlement Date</TableCell>
              <TableCell align="right">
                {(order?.settlementDate &&
                  renderOrderValue(Boolean(order?.settlementDate), order?.settlementDate)) ||
                  '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mint/Burn</TableCell>
              <TableCell align="right">{order?.type || '-'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Authorized Merchant</TableCell>
              <TableCell align="right">
                {renderOrderValue(
                  Boolean(order?.createdBy?.companyName),
                  order?.createdBy.onBehalfOf
                    ? order.createdBy.onBehalfOf?.companyName
                    : order?.createdBy?.companyName
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>User Email</TableCell>
              <TableCell align="right">
                {renderOrderValue(
                  Boolean(order?.createdBy.email ? order.createdBy.email : user?.contact.email),
                  order?.createdBy.email ? order.createdBy?.email : user?.contact.email
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Number of Tokens</TableCell>
              <TableCell align="right">
                {renderOrderValue(Boolean(order), order?.numberOfUnits || ZERO_UNITS)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Blockchain Transaction</TableCell>
              <TableCell align="right">
                {order?.transactionHash && order?.product?.destinationChain ? (
                  <ExplorerLink
                    destinationChain={order.product.destinationChain}
                    data={order.transactionHash}
                    type="transaction"
                  />
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default DealInformation;
