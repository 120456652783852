import { ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';
// import spaceTokens from 'theme/utils/spacers';
import { ButtonVariantsInterface } from 'theme/themeTokens/types/ThemeComponentTypes';
import { lightThemeTokens, darkThemeTokens } from 'theme/themes';

export const buttonPalette = (
  themeTokens: ThemeTokens,
  mode: 'light' | 'dark'
): ButtonVariantsInterface => {
  const theme = mode === 'light' ? lightThemeTokens : darkThemeTokens;
  return {
    primary: {
      color: theme.button.text.action,
      backgroundColor: theme.button.background.primary.standard,
      hover: {
        backgroundColor: theme.button.background.primary.hover,
      },
      active: {
        backgroundColor: theme.button.background.primary.pressed,
      },
      focusVisible: {
        backgroundColor: theme.button.text.standard,
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
      },
      disabled: {
        color: theme.button.text.disabled,
        backgroundColor: theme.button.background.primary.disabled,
      },
    },
    secondary: {
      color: theme.button.text.action,
      backgroundColor: theme.button.background.secondary.standard,
      hover: {
        backgroundColor: theme.button.background.secondary.hover,
      },
      active: {
        backgroundColor: theme.button.background.secondary.pressed,
      },
      focusVisible: {
        backgroundColor: theme.button.text.standard,
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
      },
      disabled: {
        color: theme.button.text.disabled,
        backgroundColor: theme.button.background.secondary.disabled,
      },
    },
    tertiary: {
      color: theme.button.text.standard,
      backgroundColor: theme.button.background.tertiary.standard,
      hover: {
        backgroundColor: theme.button.background.tertiary.hover,
      },
      active: {
        backgroundColor: theme.button.background.tertiary.pressed,
      },
      focusVisible: {
        backgroundColor: theme.button.text.standard,
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
      },
      disabled: {
        color: theme.button.text.disabled,
        backgroundColor: theme.button.background.tertiary.disabled,
      },
    },
    danger: {
      color: theme.button.text.action,
      backgroundColor: theme.button.background.danger.standard,
      hover: {
        backgroundColor: theme.button.background.danger.hover,
      },
      active: {
        backgroundColor: theme.button.background.danger.pressed,
      },
      focusVisible: {
        backgroundColor: theme.button.text.standard,
        boxShadow: '0px 0px 0px 2px #FFF, 0px 0px 0px 4px #000',
      },
      disabled: {
        color: theme.button.text.disabled,
        backgroundColor: theme.button.background.danger.disabled,
      },
    },
  };
};
