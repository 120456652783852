import { Emphasis } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';

export const tertiaryButton = (theme: Theme) => {
  const tertiaryButton = theme.customComponents.buttonPalette.tertiary;

  return [
    {
      props: (props: { variant: string; emphasis: Emphasis }) => props.variant === 'tertiary',
      style: {
        color: tertiaryButton.color,
        border: `1.25px solid ${tertiaryButton.backgroundColor}`,
        backgroundColor: 'unset',
        '&:hover': {
          backgroundColor: 'unset',
          border: `1.25px solid ${tertiaryButton.hover?.backgroundColor}`,
        },
        '&:active': {
          border: tertiaryButton.active?.backgroundColor,
        },
        '&:focus-visible': {
          boxShadow: tertiaryButton.focusVisible?.boxShadow,
        },
        '&.Mui-disabled': {
          color: tertiaryButton.disabled?.color,
          backgroundColor: tertiaryButton.disabled?.backgroundColor,
          '& svg': {
            fill: tertiaryButton.disabled?.color,
          },
        },
        span: { color: tertiaryButton.color },
        '& svg': {
          fill: tertiaryButton.color,
        },
      },
    },
  ];
};
