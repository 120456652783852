import { Button } from 'components/Button/Button';
import {
  ActionbarButtonsWrapper,
  StyledActionBarContainer,
} from 'shared/Tables/PCFTable/PCFTable.styles';
import { CheckedItem } from './useBatchOperations';
import { PortfolioComposition } from 'utils/types/pcfs';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';

type PCFTableActionbarProps = {
  checkedItems: CheckedItem[];
  pcfs: PortfolioComposition[];
};

const PCFTableActionbar = ({ checkedItems, pcfs }: PCFTableActionbarProps) => {
  const dispatch = useAppDispatch();
  if (checkedItems.length < 1) return null;

  const downloadCheckNotifications = (filteredList: PortfolioComposition[]) => {
    if (filteredList.length === 0)
      return dispatch(
        createNotification({
          message: `There are no PCFs available for download for selected PCFs.`,
          title: 'PCF Download',
          type: 'warning',
        })
      );
    if (filteredList.length !== checkedItems.length)
      dispatch(
        createNotification({
          message: `Some of the selected PCFs haven’t been uploaded yet.`,
          title: 'PCF Download',
          type: 'info',
        })
      );
  };

  const handleXLSDownload = () => {
    const filteredPCFs = pcfs.filter((pcf) => {
      return (
        checkedItems.find((item) => item._id === pcf._id) && pcf?.documents?.officialExcel?.url
      );
    });
    filteredPCFs.forEach((pcf) => {
      pcf?.documents?.officialPdf?.url && window.open(pcf?.documents?.officialExcel?.url, '_blank');
    });
    downloadCheckNotifications(filteredPCFs);
  };

  const handlePDFDownload = () => {
    const filteredPCFs = pcfs.filter((pcf) => {
      return checkedItems.find((item) => item._id === pcf._id) && pcf?.documents?.officialPdf?.url;
    });
    filteredPCFs.forEach((pcf) => {
      pcf?.documents?.officialPdf?.url && window.open(pcf?.documents?.officialPdf?.url, '_blank');
    });
    downloadCheckNotifications(filteredPCFs);
  };

  return (
    <StyledActionBarContainer>
      <div>{checkedItems.length} Selected</div>
      <ActionbarButtonsWrapper>
        <Button onClick={handlePDFDownload} endIcon={<DownloadIcon />}>
          Download PDF
        </Button>
        <Button onClick={handleXLSDownload} endIcon={<DownloadIcon />}>
          Download XLS
        </Button>
      </ActionbarButtonsWrapper>
    </StyledActionBarContainer>
  );
};

export default PCFTableActionbar;
