import { Button } from 'components/Button/Button';
import { Box, styled as MuiStyled } from '@mui/material';

export const StyledPageHeaderWrapper = MuiStyled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacers['spacing-32']};
`;

export const StyledCloseRouteButton = MuiStyled(Button)(() => ({
  width: '40px',
  height: '40px',
  padding: '12px',
  svg: {
    margin: 'auto',
  },
}));
