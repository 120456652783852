import { TableCell as MuiTableCell, TableCellProps } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import Tooltip from 'components/Tooltip';
import TooltipContent from 'components/Tooltip/TooltipContent';
import { neutral } from 'theme/colors/baseColors';

interface CustomTableCellProps {
  align?: 'left' | 'center' | 'right';
  nowrap?: 'true' | 'false';
  withActions?: boolean;
  pointer?: boolean;
  minWidth?: string;
  maxWidth?: string;
  disabled?: Boolean;
  error?: boolean;
  sizeprop?: 'small' | 'medium' | 'large';
  hideOnSmallScreen?: boolean;
  hoverText?: string | number;
  enableOnHover?: Boolean;
  fixedColumn?: Boolean;
}

export const FULL_SPAN = 9999;

const StyledTableCell = MuiStyled(MuiTableCell)<CustomTableCellProps>(
  ({
    theme,
    nowrap = false.toString(),
    error,
    sizeprop = 'medium',
    withActions,
    minWidth,
    maxWidth,
    pointer,
    disabled,
    align,
    hideOnSmallScreen,
    color,
  }) => ({
    whiteSpace: nowrap === 'false' ? 'nowrap' : 'normal',
    color: color ?? error ? theme.palette.error.main : theme.palette.text.primary,
    minWidth: minWidth ?? 'unset',
    maxWidth: maxWidth ?? 'unset',
    textAlign: align || 'left',
    cursor: pointer ? 'pointer' : 'default',
    display: withActions ? 'flex' : 'table-cell',
    padding: `0 ${theme.spacers['spacing-16']}`,
    verticalAlign: 'middle',
    '&:hover': disabled
      ? {
          border: `1px solid ${theme.palette.blue[300]}`,
          background: '#F7F7F7',
          cursor: 'not-allowed',
        }
      : undefined,
    '&.MuiTableCell-root': {
      padding: '0 24px',
      lineHeight: 'unset',
      height: {
        small: '32px',
        medium: '48px',
        large: '68px',
      }[sizeprop],
    },
    '& > :first-child': align === 'right' ? { marginLeft: 'auto' } : undefined,
    '.MuiCircularProgress-svg': {
      padding: 0,
    },
    ...(hideOnSmallScreen && {
      '@media (max-width: 1600px)': {
        display: 'none',
      },
    }),
  })
);

// Add type for valid CSS positions
type Position = 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';

export const fixedColumnStyle = {
  position: 'sticky' as Position,
  left: 0,
  zIndex: 10,
  backgroundColor: neutral[20],
};

export const TableCell = ({
  children,
  hoverText = '',
  enableOnHover,
  fixedColumn,
  ...props
}: CustomTableCellProps & TableCellProps) => {
  props.style = { ...props.style, ...(fixedColumn ? fixedColumnStyle : {}) };
  return enableOnHover ? (
    <StyledTableCell {...props}>
      <Tooltip
        placement="top"
        title={<TooltipContent description={hoverText?.toString()}></TooltipContent>}
        disabled={!enableOnHover}
      >
        <span>{children}</span>
      </Tooltip>
    </StyledTableCell>
  ) : (
    <StyledTableCell {...props}>{children}</StyledTableCell>
  );
};

export default TableCell;
