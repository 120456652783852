/* eslint-disable complexity */
import { Button } from 'components/Button/Button';
import Form from 'components/Form/Form';
import { SaveProgress } from 'pages/Instruments/components/SaveProgress';
import { useParams } from 'react-router-dom';
import ProductConfigHeader from 'components/Form/components/ProductConfigHeader';
import FormGridContainer from 'components/Form/components/FormGridContainer';
import FormGridItem from 'components/Form/components/FormGridItem';
import { useCompanyETPs } from 'pages/Partners/hooks/useCompanyProducts';
import { ProductStatus } from 'utils/types/product';
import { CompanyProductsDto } from 'utils/types';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';
import { errorNotification, successNotification } from 'shared/Notifications/general.notifications';
import { useMutation } from 'react-query';
import { requestPatchCompanyETPs } from 'utils/api/partners';
import { companyETPsSchemaUrl } from 'components/Form/formSchemas';
import ProductSelector from 'components/ProductSelector';

export const ETPsStep = () => {
  const { id: partnerId } = useParams();
  const { data, isLoading, refetch } = useCompanyETPs(partnerId ?? '');

  const dispatch = useAppDispatch();
  const updateCompanyETPsMutation = useMutation({
    mutationFn: (CompanyProductsDto: CompanyProductsDto) =>
      requestPatchCompanyETPs(partnerId ?? '', CompanyProductsDto),
    onSuccess: () => {
      dispatch(
        createNotification(
          successNotification('Successfully updated partner etps', "Partner's ETPs Update")
        )
      );
      refetch();
    },
    onError: (error: Error) => {
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
      refetch();
    },
  });

  const handleOnSubmit = (companyProductsDto: CompanyProductsDto) => {
    updateCompanyETPsMutation.mutate(companyProductsDto);
  };

  return (
    <div>
      {partnerId && data && (
        <Form<CompanyProductsDto>
          initialValues={data}
          onSubmit={handleOnSubmit}
          schemaUrl={companyETPsSchemaUrl}
        >
          <FormGridContainer>
            <FormGridItem md={12}>
              <ProductConfigHeader title="Products" />
            </FormGridItem>
            <FormGridItem>
              <ProductSelector
                permissionFilter="update"
                name="productIds"
                isMulti
                status={[ProductStatus.ACTIVE, ProductStatus.PENDING, ProductStatus.IN_REVIEW]}
              />
            </FormGridItem>
            <FormGridItem>
              {(updateCompanyETPsMutation.isLoading || isLoading) && <SaveProgress />}
            </FormGridItem>
            <FormGridItem display={'flex'} gap={2} justifyContent={'flex-end'} md={12}>
              <div>
                <Button type="submit" data-qa-id="nextButton" fullWidth variant="primary">
                  Save
                </Button>
              </div>
            </FormGridItem>
          </FormGridContainer>
        </Form>
      )}
    </div>
  );
};
