import useAppDispatch from 'hooks/useAppDispatch';
import {
  CustomBasketDeliverable,
  Deliverable,
  OrderFormProps,
  OrderScope,
} from 'utils/types/orders';
import { OrderPageHeader } from 'pages/Orders/Orders/Order/components/OrderPageHeader';
import { SingleOrderView } from 'pages/Orders/Orders/Order/components/SingleOrderView';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useCallback, useState, useEffect } from 'react';
import { useCreateOrder } from 'pages/Orders/Orders/Order/hooks/useCreateOrder';
import { useInstrumentsDelegatedOrder } from 'hooks/useInstrumentsQuery';
import { useLoadOrderDetails } from 'pages/Orders/Orders/Order/hooks/useLoadOrderDetails';
import { TitleArea } from 'components/TitleArea/TitleArea';
import { recalculateCustomBasketDeliverables } from 'pages/Orders/Orders/utils/customBasketDeliverables';
import BigNumber from 'bignumber.js';

const DelegatedOrder = () => {
  const dispatch = useAppDispatch();
  const [updatedOrderData, setUpdatedOrderData] = useState<OrderFormProps>();
  const [seedDeliverables, setSeedDeliverables] = useState<Deliverable[]>([]);
  const [customBasketDeliverables, setCustomBasketDeliverables] = useState<
    CustomBasketDeliverable[]
  >([]);
  const { user } = useAuthenticatedUser();
  const { instruments, isLoading: loadingInstruments } = useInstrumentsDelegatedOrder();
  const instrument = instruments?.find((inst) => inst._id === updatedOrderData?.productId);
  const { data: order, isLoading, isFetching } = useLoadOrderDetails(updatedOrderData, instrument);
  const loading = isLoading || isFetching;
  const { createDelegatedOrder } = useCreateOrder();

  useEffect(() => {
    const isSeedOrder = Boolean(order?.isSeed);
    isSeedOrder &&
      !seedDeliverables.length &&
      setSeedDeliverables([...(order?.deliveries?.expected ?? [])]);
  }, [order, instrument?._id]);

  useEffect(() => {
    const isCustomBasketOrder =
      Boolean(order) && !Boolean(order?._id) && order?.scope === OrderScope.CUSTOM_BASKET;
    const lockedDeliverables = customBasketDeliverables.reduce<
      Record<string, CustomBasketDeliverable>
    >((lockedList, deliverable) => {
      if (deliverable.isLocked) {
        lockedList[deliverable.ticker] = deliverable;
      }

      return lockedList;
    }, {});

    const expectedDeliverables =
      order?.deliveries?.expected?.map((deliverable) => ({
        ...deliverable,
        weight: deliverable.weight,
        isLocked: Boolean(lockedDeliverables[deliverable.ticker]),
      })) ?? [];

    isCustomBasketOrder && setCustomBasketDeliverables(expectedDeliverables);
  }, [order, instrument?._id]);

  const handleOnSubmit = async (orderToSubmit: OrderFormProps) => {
    const isSeedOrder = Boolean(order?.isSeed);
    const isCustomBasketOrder =
      Boolean(order) && !Boolean(order?._id) && order?.scope === OrderScope.CUSTOM_BASKET;

    let deliveries: Deliverable[] | CustomBasketDeliverable[] = [];
    if (isSeedOrder) {
      deliveries = seedDeliverables;
    } else if (isCustomBasketOrder) {
      deliveries = customBasketDeliverables;
    }

    createDelegatedOrder.mutate({
      ...orderToSubmit,
      deliveries,
      submit: order?.hasPortfolioCompositionApproved || order?.isSeed,
    });
  };

  const handleChangeOrder = useCallback(
    async (orderFormData: OrderFormProps | undefined) => {
      const isCustomBasketOrder =
        Boolean(order) && !Boolean(order?._id) && order?.scope === OrderScope.CUSTOM_BASKET;

      let deliveries: CustomBasketDeliverable[] = [];
      if (isCustomBasketOrder) {
        deliveries = customBasketDeliverables;
      }
      if (orderFormData?.productId) {
        try {
          setUpdatedOrderData({
            ...orderFormData,
            ...(deliveries.length ? { deliveries } : {}),
          });
        } catch (err) {
          const error = err as Error;
          dispatch(createNotification(errorNotification(error.message, 'Error'), error));
        }
      } else {
        setUpdatedOrderData(undefined);
        setSeedDeliverables([]);
      }
    },
    [dispatch, customBasketDeliverables, order?.scope]
  );

  const handleChangeSeedDeliverable = (deliverable: Deliverable) => {
    setSeedDeliverables([
      ...seedDeliverables.filter(
        (seedDeliverable) => seedDeliverable.ticker !== deliverable.ticker
      ),
      deliverable,
    ]);
  };

  const handleChangeCustomBasketDeliverable = (
    deliverables: CustomBasketDeliverable | CustomBasketDeliverable[]
  ) => {
    const deliverablesArray = Array.isArray(deliverables) ? deliverables : [deliverables];

    // Calculate updates for all deliverables
    let updatedDeliverables = [...customBasketDeliverables];
    deliverablesArray.forEach((deliverable) => {
      updatedDeliverables = recalculateCustomBasketDeliverables(updatedDeliverables, deliverable);
    });

    setCustomBasketDeliverables(updatedDeliverables);

    // Check if any deliverable weight has changed
    const hasWeightChanges = deliverablesArray.some((deliverable) => {
      const oldDeliverable = customBasketDeliverables.find((d) => d.ticker === deliverable.ticker);
      return !new BigNumber(oldDeliverable?.weight || 0).eq(new BigNumber(deliverable.weight || 0));
    });

    if (hasWeightChanges) {
      const orderWithNewDeliverables = updatedOrderData
        ? { ...updatedOrderData, deliveries: updatedDeliverables }
        : undefined;

      setUpdatedOrderData(orderWithNewDeliverables);
    }
  };

  return (
    <>
      <TitleArea
        title={<OrderPageHeader productType="ETP" isNewOrder isDelegatedOrder />}
        showBackButton={true}
      />
      <SingleOrderView
        customBasketDeliverables={customBasketDeliverables}
        productType="ETP"
        changeOrder={handleChangeOrder}
        onChangeSeedDeliverable={handleChangeSeedDeliverable}
        onChangeCustomBasketDeliverable={handleChangeCustomBasketDeliverable}
        isSubmitting={createDelegatedOrder.isLoading}
        loading={loadingInstruments || loading}
        onSubmit={handleOnSubmit}
        order={order}
        orderFlow="delegate"
        seedDeliverables={seedDeliverables}
        user={user}
      />
    </>
  );
};

export default DelegatedOrder;
