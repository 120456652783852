import { NotificationWithoutId } from 'utils/types';

export const ORDER_NOTIFICATIONS: { [key: string]: Omit<NotificationWithoutId, 'type'> } = {
  CANCELLED: {
    title: 'Order Cancelled',
    message: 'You successfully cancelled an order',
  },
  CONFIRMED: {
    title: 'Order Confirmed',
    message: 'You successfully confirmed an order',
  },
  READY: {
    title: 'Order Ready',
    message: 'Order status updated to ready',
  },
  DEFERRED: {
    title: 'Order Deferred',
    message: 'You successfully deferred an order',
  },
  SETTLED: {
    title: 'Order Settled',
    message: 'You successfully settled an order',
  },
  SUBMIT: {
    title: 'Order Submitted',
    message: 'You successfully submitted an order',
  },
};
