import ProductIcon from 'components/ProductIcon';
import Select from 'components/Select';
import {
  ProductStatus,
  TokenType,
  Token,
  TokenList,
  ProductBaseActions,
} from 'utils/types/product';
import { GroupedOptionsType, OnChangeType } from 'components/Select/Select.types';
import { StyledProductSelectorContainer } from 'components/ProductSelector/ProductSelector.styles';
import { groupBy, isArray } from 'lodash';
import { useEffect, useState } from 'react';
import { useGetTokensQuery } from 'hooks/useTokens';
import { capitalizeFirstLetter } from 'utils/formatting';
import MultiSelect from 'components/MultiSelect/MultiSelect';

interface ProductSelectorProps {
  className?: string;
  clearable?: boolean;
  ['data-qa-id']?: string;
  ['data-qa-options-id']?: string;
  fullWidth?: boolean;
  label?: string;
  name?: string;
  onChange?: OnChangeType<string>;
  permissionFilter?: ProductBaseActions;
  placeholder?: string;
  resetOnChange?: string[];
  status?: ProductStatus[];
  value?: string | string[];
  disabled?: Boolean;
  productType?: TokenType;
  isMulti?: boolean;
}

function mapProductsToOptions(
  productsList: TokenList | Token[],
  permissionFilter?: ProductBaseActions,
  productType?: TokenType
) {
  let products;

  if (Array.isArray(products)) {
    products = productsList as Token[];
  } else {
    products = Object.values(productsList || {}) as Token[];
  }

  const mappedProducts = products
    .filter(
      (product) =>
        !permissionFilter || Boolean(product._actions && product._actions[permissionFilter])
    )
    .filter((product) => (productType ? product.type === productType : true))
    .map((product) => {
      return {
        label: `${product.ticker} (${capitalizeFirstLetter(product.destinationChain)})`,
        value: product._id,
        type: TokenType.SINGLE_ASSET,
        icon: <ProductIcon iconUrl={product.fundIcon} ticker={product.ticker} size="16px" />,
      };
    });

  return groupBy(mappedProducts, 'type');
}

function TokenProductSelector({
  clearable,
  'data-qa-id': dataQaId,
  'data-qa-options-id': dataQaOptionsId,
  fullWidth,
  label,
  name,
  onChange,
  permissionFilter,
  value,
  placeholder,
  className,
  status = [ProductStatus.ACTIVE],
  disabled,
  productType = TokenType.SINGLE_ASSET,
  isMulti,
}: ProductSelectorProps) {
  const { data: tokens = {} } = useGetTokensQuery({ status }, null, true);

  const [options, setOptions] = useState<GroupedOptionsType<string>>();

  const handleFilter = (filter: string | undefined) => {
    if (filter) {
      const normalizedFilter = filter.toLowerCase();

      const filteredProducts = Object.values<Token>(tokens || {}).filter(
        (option: Token) =>
          option.ticker.toLowerCase().includes(normalizedFilter) ||
          option.name.toLowerCase().includes(normalizedFilter)
      );
      setOptions(mapProductsToOptions(filteredProducts, permissionFilter, productType));
    } else {
      return setOptions(mapProductsToOptions(tokens || {}, permissionFilter, productType));
    }
  };

  useEffect(() => {
    setOptions(mapProductsToOptions(tokens || [], permissionFilter, productType));
  }, [tokens, permissionFilter]);

  if (isMulti) {
    return (
      <StyledProductSelectorContainer fullwidth={fullWidth} className={className}>
        <MultiSelect
          data-qa-id={dataQaId}
          data-qa-options-id={dataQaOptionsId}
          label={label}
          name={name}
          onChange={onChange}
          onFilter={handleFilter}
          options={options}
          placeholder={placeholder ?? 'Select products'}
          value={isArray(value) ? value : []}
          disabled={Boolean(disabled)}
        />
      </StyledProductSelectorContainer>
    );
  }

  return (
    <StyledProductSelectorContainer fullwidth={fullWidth} className={className}>
      <Select
        clearable={clearable}
        data-qa-id={dataQaId}
        data-qa-options-id={dataQaOptionsId}
        filterable
        label={label}
        name={name}
        onChange={onChange}
        onFilter={handleFilter}
        options={options}
        placeholder={placeholder ?? 'Select product'}
        value={isArray(value) ? undefined : value}
        disabled={Boolean(disabled)}
      />
    </StyledProductSelectorContainer>
  );
}

export default TokenProductSelector;
