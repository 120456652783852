// components
import { Button } from 'components/Button/Button';
import { CalendarCountryTitles } from 'utils/constants/calendar';
import { TableCell } from 'components/Table/TableCell';
import { TableRow } from 'components/Table/TableRow';
import { ButtonDropdown } from 'components/Button/ButtonDropdown';
// hooks
import { useState } from 'react';
//icons
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/chevron-up.svg';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
//styles
import { StyledIcon } from 'layouts/DashboardContainer/SideNavigationMenu.style';
import {
  MuiStyledTableCellWithChevron,
  MuiStyledCalendarEventRow,
  MuiStyledAddButtonRowWrapper,
  MuiCalendarTableCell,
} from 'shared/Tables/CalendarsTable/CalendarsTable.styles';
//types
import { Calendar, CalendarEvent } from 'utils/types/calendar';
//utils
import { format, parseISO } from 'date-fns';
import { dailyTableDateFormat } from 'shared/Tables//table.utils';
import { ButtonDropdownItem } from 'components/Button/ButtonDropdownItem';

type CalendarsTableProps = {
  calendar: Calendar;
  deleteAction: () => void;
  editAction: () => void;
  subitemEditAction: (calendarEvent?: CalendarEvent) => void;
  subitemDeleteAction: (calendarEvent: CalendarEvent) => void;
  showActionsCell: boolean;
};

export const CalendarsTableRow = ({
  calendar,
  editAction,
  deleteAction,
  subitemEditAction,
  subitemDeleteAction,
  showActionsCell,
}: CalendarsTableProps) => {
  const [expanded, setExpanded] = useState(false);
  const onRowClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <TableRow data-qa-id={`${calendar.name}-row`} key={calendar._id} onClick={onRowClick}>
        <MuiStyledTableCellWithChevron data-qa-id={`${calendar.name}-name`}>
          <StyledIcon>{expanded ? <ChevronUp /> : <ChevronDown />}</StyledIcon>
          {calendar.name}
        </MuiStyledTableCellWithChevron>
        <MuiCalendarTableCell data-qa-id="country">
          {calendar.country} - {CalendarCountryTitles[calendar.country]}
        </MuiCalendarTableCell>
        <MuiCalendarTableCell data-qa-id="year">{calendar.year}</MuiCalendarTableCell>
        <MuiCalendarTableCell data-qa-id="timezone">{calendar.timezone}</MuiCalendarTableCell>
        <MuiCalendarTableCell data-qa-id="lastUpdated">
          {calendar.updatedAt && format(parseISO(calendar.updatedAt), dailyTableDateFormat)}
        </MuiCalendarTableCell>
        <TableCell data-qa-id="editIcons">
          {showActionsCell && (
            <>
              <ButtonDropdown id={calendar._id ?? calendar.name} className="actionsCell">
                {calendar._actions?.update && (
                  <ButtonDropdownItem
                    id="editCalendar"
                    label="Edit Calendar"
                    onClick={editAction}
                  />
                )}
                {calendar._actions?.delete && (
                  <ButtonDropdownItem
                    id="deleteCalendar"
                    label="Delete Calendar"
                    onClick={deleteAction}
                  />
                )}
              </ButtonDropdown>
            </>
          )}
        </TableCell>
      </TableRow>
      {expanded && (
        <>
          {calendar.events.map((calendarEvent, index) => {
            return (
              <MuiStyledCalendarEventRow
                data-qa-id={`${calendar.name}-holiday`}
                key={calendarEvent.date + index}
                onClick={() => {
                  subitemEditAction(calendarEvent);
                }}
              >
                <TableCell
                  data-qa-id={`${calendar.name}-${calendarEvent.description}-holidayName`}
                  colSpan={3}
                >
                  {calendarEvent.description}
                </TableCell>
                <TableCell
                  data-qa-id={`${calendar.name}-${calendarEvent.description}-holidayDate`}
                  colSpan={2}
                >
                  {format(parseISO(calendarEvent.date), dailyTableDateFormat)}
                </TableCell>
                {showActionsCell && (
                  <TableCell data-qa-id="editIcons">
                    <ButtonDropdown id={calendar._id ?? calendar.name} className="actionsCell">
                      <ButtonDropdownItem
                        id="editHoliday"
                        label="Edit Holiday"
                        onClick={() => {
                          subitemEditAction(calendarEvent);
                        }}
                      />

                      <ButtonDropdownItem
                        id="deleteHoliday"
                        label="Delete Holiday"
                        onClick={() => {
                          subitemDeleteAction(calendarEvent);
                        }}
                      />
                    </ButtonDropdown>
                  </TableCell>
                )}
              </MuiStyledCalendarEventRow>
            );
          })}
          <TableRow>
            <MuiStyledAddButtonRowWrapper colSpan={6}>
              <Button
                data-qa-id="addHolidayButton"
                variant="secondary"
                size="small"
                onClick={() => subitemEditAction()}
                startIcon={<PlusIcon />}
              >
                Add Holiday
              </Button>
            </MuiStyledAddButtonRowWrapper>
          </TableRow>
        </>
      )}
    </>
  );
};
