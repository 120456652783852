import { Button } from 'components/Button/Button';
import { Column, Row } from 'components/Grid';
import { logout } from 'utils/user/storage';
import { useAuthenticatedUser } from 'store/user/selectors';
import { useNavigate } from 'react-router-dom';
import { publicRoutesUrls } from 'router/constants';
import { ResetOwn2FAModal } from './ResetOwn2FAModal/ResetOwn2FAModal';
import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Card } from 'components/Card/Card';

export const UserSettingsSecurity = () => {
  const { user } = useAuthenticatedUser();
  const [showResetOwn2FAModal, setShowResetOwn2FAModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div>
      {user && (
        <>
          <Card
            noPadding
            header={
              <Box padding={2}>
                <Typography variant="subheadingMedium">Security Settings</Typography>
              </Box>
            }
            body={
              <Box padding={2}>
                <Row>
                  <Column gutter="noGutter">
                    <Typography variant="subheadingMedium">Password</Typography>
                  </Column>
                  <Column cols={10} gutter="noXGutter">
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography variant="bodyMedium" fontWeight={'bold'}>
                        Update password
                      </Typography>
                      <Typography variant="bodyMedium">
                        To ensure the protection of your personal information, it’s recommended to
                        update your password every 3 months.
                      </Typography>
                    </Box>
                  </Column>
                  <Column cols={2}>
                    <Button
                      variant="secondary"
                      fullWidth
                      disabled
                      sx={{ justifyContent: 'space-around' }}
                    >
                      Update password
                    </Button>
                  </Column>
                </Row>
                <Row>
                  <Column gutter="noGutter">
                    <Typography variant="subheadingMedium">
                      2-Factor-Authentication (2FA)
                    </Typography>
                  </Column>
                  <Column cols={10} gutter="noXGutter">
                    <Box display={'flex'} flexDirection={'column'}>
                      <Typography variant="bodyMedium" fontWeight={'bold'}>
                        Reset 2FA
                      </Typography>
                      <Typography variant="bodyMedium">
                        Use your recovery code in order to reset your 2-Factor-Authentication.
                      </Typography>
                    </Box>
                  </Column>
                  <Column cols={2}>
                    <Button
                      variant="secondary"
                      fullWidth
                      justifyContent="space-around"
                      onClick={() => {
                        setShowResetOwn2FAModal(true);
                      }}
                    >
                      Reset 2FA
                    </Button>
                  </Column>
                </Row>
                <Row>
                  <Column cols={10} gutter="noXGutter">
                    <div>
                      <Typography variant="subheadingMedium">Log out of your account</Typography>
                    </div>
                  </Column>
                  <Column cols={2}>
                    <Button
                      variant="secondary"
                      fullWidth
                      justifyContent="space-around"
                      onClick={() => {
                        logout(true);
                        navigate(publicRoutesUrls.login);
                      }}
                    >
                      Logout
                    </Button>
                  </Column>
                </Row>
              </Box>
            }
          />
        </>
      )}
      {showResetOwn2FAModal && (
        <ResetOwn2FAModal onCloseModalAction={() => setShowResetOwn2FAModal(false)} />
      )}
    </div>
  );
};
