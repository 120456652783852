import { ThemeComponents, ThemeTokens } from 'theme/themeTokens/types/ThemeTokensTypes';
import { linkPalette } from 'theme/themeTokens/components/componentPalettes/linkPalette';
import { buttonPalette } from 'theme/themeTokens/components/componentPalettes/buttonPalette';
import { chipPalette } from './componentPalettes/chipPalette';
import { sideNavPalette } from './componentPalettes/sideNavPalette';
import { navigationPalette } from './componentPalettes/navigationPalette';
import { tabPalette } from './componentPalettes/tabPalette';
import { formPalette } from './componentPalettes/formPalette';

export const themeComponents = (
  themeTokens: ThemeTokens,
  mode: 'light' | 'dark' = 'light'
): ThemeComponents => ({
  sideNavMenuItemPalette: sideNavPalette(themeTokens, mode),
  linkPalette: linkPalette(themeTokens),
  buttonPalette: buttonPalette(themeTokens, mode),
  chip: chipPalette(themeTokens),
  navigation: navigationPalette(themeTokens),
  tab: tabPalette(themeTokens),
  form: formPalette(themeTokens),
});
