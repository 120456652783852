import { NAVReviewItem } from 'utils/types/nav';
import { useEffect, useMemo, useState } from 'react';
import { mapNavReviewDetailsTransactions } from 'pages/Ledger/NAV/NAVDetailsPage/utils/mapper';
import { useNAVReviewForValuationDate } from './useNavReviewForValuationDate';
import { useMutation } from 'react-query';
import { createEntityApiCall } from 'utils/api/crudActions';
import useAppDispatch from 'hooks/useAppDispatch';
import { createNotification } from 'store/notifications/actions';
import { errorNotification } from 'shared/Notifications/general.notifications';
import { Instrument } from 'utils/types';
import { loadProductData } from 'utils/api/instruments';

export const useNAVReviewItemDetailsQuery = ({
  constituentTicker,
  valuationdate,
  productId,
}: {
  constituentTicker: string;
  valuationdate: string;
  productId: string;
}) => {
  const dispatch = useAppDispatch();
  const [navReviewItem, setNavReviewItem] = useState<NAVReviewItem>();
  const [reviewNAVItemOldValue, setreviewNAVItemOldValue] = useState<NAVReviewItem>();
  const [productData, setProductData] = useState<Instrument | null>(null);

  const {
    data: navReviewItemRes,
    isLoading,
    isFetching,
    refetch,
  } = useNAVReviewForValuationDate(productId, constituentTicker, valuationdate);

  useEffect(() => {
    const fetchProductData = async () => {
      const data = await loadProductData(productId);
      setProductData(data);
    };

    fetchProductData();
  }, [productId]);

  useEffect(() => {
    if (!reviewNAVItemOldValue) setreviewNAVItemOldValue(structuredClone(navReviewItemRes));
    setNavReviewItem(structuredClone(navReviewItemRes));
  }, [navReviewItemRes, isFetching, isLoading, refetch]);
  const mappedNavDetailsTransactions = useMemo(() => {
    return mapNavReviewDetailsTransactions(navReviewItem);
  }, [navReviewItem]);

  const calculateNavAndRefetchMutation = useMutation({
    mutationFn: () => {
      return createEntityApiCall(
        null,
        navReviewItemRes?._actions?.calculateNetAssetValue?.uri ?? ''
      );
    },
    onSuccess: () => {
      refetch();
    },
    onError: (err) => {
      const error = err as Error;
      dispatch(createNotification(errorNotification(error.message ?? ''), error));
    },
  });

  return {
    reviewNAVItemOldValue,
    mappedNavDetailsTransactions,
    navReviewItem,
    productData,
    isLoading: isLoading || isFetching || calculateNavAndRefetchMutation.isLoading,
    updateDeltaNavReviewItem: (item: NAVReviewItem) => {
      setNavReviewItem({
        ...item,
        product: item.product ?? navReviewItem?.product,
        ...(navReviewItem?.custodianBalances && {
          custodianBalances: item.custodianBalances ?? navReviewItem?.custodianBalances,
        }),
        ...(navReviewItem?.custodianTotals && {
          custodianTotals: item.custodianTotals ?? navReviewItem?.custodianTotals,
        }),
      }); // TODO: we should receive this from BE
    },
    refetch: refetch,
    calculateNavAndRefetchMutation,
  };
};
