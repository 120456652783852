import { AxiosError, AxiosResponse } from 'axios';

// types
import { FormSchemaResponse } from 'components/Form/Form.types';

// helpers
import { axiosInstance } from 'utils/axiosOnyx';

export const loginSchemaUrl = '/auth/login/schema';
export const resetPasswordSchemaUrl = '/auth/password/reset/schema';
export const instrumentGeneralDetailsSchemaUrl = '/products/schema/data=general';
export const instrumentMarketDetailsSchemaUrl = '/products/schema/data=market';
export const instrumentFactsheetSchemaUrl = '/products/schema/data=factsheet';
export const instrumentLegalDocumentsSchemaUrl = '/products/schema/data=legal-documents';
export const instrumentPcfSchemaUrl = '/products/schema/data=pcf';
export const instrumentBenchmarkDetailsSchemaUrl = '/products/schema/data=benchmark';
export const instrumentConstituentAssetsSchemaUrl = '/products/schema/data=constituent';
export const instrumentExchangeLocalTickerSchemaUrl = '/products/schema/data=exchange-local-ticker';
export const instrumentPartnerSchemaUrl = '/products/schema/data=partners';
export const instrumentCustodianSchemaUrl = '/products/schema/data=custodian-account';
export const instrumentCustodianWalletSchemaUrl = '/products/schema/data=wallets-custodian';
export const instrumentUnifiedWalletSchemaUrl = '/products/schema/data=wallets-unified';
export const exchangeSchemaUrl = '/admin/exchanges/schema';
export const companiesSchemaUrl = '/companies/id=any/schema';
export const companySchemaUrl = '/companies/schema';
export const companyETPsSchemaUrl = '/companies/etps/schema';
export const companyTokensSchemaUrl = '/companies/tokens/schema';
export const createUserSchemaUrl = '/companies/id=any/users/schema';
export const createCustodianWalletSchemaUrl = '/companies/wallets/type=CUSTODIAN/schema';
export const createOwnWalletSchemaUrl = '/companies/wallets/type=OWN/schema';
export const createAdminCaledarSchemaUrl = '/admin/calendars/schema';
export const createAdminCaledarEventSchemaUrl = '/admin/calendars/events/schema';
export const orderSchemaUrl = '/orders/schema';
export const orderDelegatedSchemaUrl = '/orders/delegated/schema';
export const tokenOrderSchemaUrl = 'orders/type=token/schema';
export const tokenOrderDelegatedSchemaUrl = '/orders/type=token/delegated/schema';
export const updateMeSchemaUrl = '/users/me/schema';
export const contactSchemaUrl = '/admin/contacts/schema';
export const contactListSchemaUrl = '/admin/contact-lists/schema';
export const twoFAResetSchemaUrl = '/auth/2fa/reset/schema';
export const registerStockSplitSchemaUrl = '/securities-registry/stock-splits/schema';
/* ----
TOKENS
---- */
export const tokenGeneralDetailsSchemaUrl = '/products/type=token/schema/data=general';
export const tokenConstituentAssetsSchemaUrl = '/products/type=token/schema/data=constituent';
export const tokenCustodianSchemaUrl = '/products/type=token/schema/data=custodian-account';
export const tokenPartnerSchemaUrl = '/products/type=token/schema/data=partners';
export const tokenCustodianWalletSchemaUrl = '/products/type=token/schema/data=wallets-custodian';
export const tokenUnifiedWalletSchemaUrl = '/products/type=token/schema/data=wallets-unified';

export const getFormSchema = (
  schemaUrl: string
): Promise<AxiosResponse<FormSchemaResponse, AxiosError>> | undefined =>
  axiosInstance.get(schemaUrl);
