import { Box } from '@mui/material';
import SingleDatePicker, { DateValue } from 'components/DatepickerSingleDate/index';
import { parseISO } from 'date-fns';
import { Card } from 'components/Card/Card';
import { Button } from 'components/Button/Button';
import { ReactComponent as Reload } from 'assets/reload.svg';
import { ReactComponent as DownloadIcon } from 'assets/download.svg';

interface NAVDetailsPageHeaderProps {
  handleDateChange: (value: DateValue) => void;
  navReviewDate: string;
  onRefreshData?: () => void;
  downloadCsv?: () => void;
  downloadPdf?: () => void;
}

export const NAVDetailsPageHeader = ({
  navReviewDate,
  handleDateChange,
  onRefreshData,
  downloadCsv,
}: NAVDetailsPageHeaderProps) => {
  const date = parseISO(navReviewDate) ?? null;
  const inputDateFormat = 'dd/MM/yyyy';

  const downloadButton = (
    <Button
      id="downloadCSV"
      onClick={downloadCsv ? downloadCsv : () => {}}
      variant="tertiary"
      startIcon={<DownloadIcon />}
    >
      CSV
    </Button>
  );

  return (
    <Card
      sx={{ padding: 2 }}
      header={
        <Box display={'flex'} justifyContent={'space-between'}>
          <SingleDatePicker
            date={date}
            onChange={handleDateChange}
            maxDate={new Date()}
            inputDateFormat={inputDateFormat}
            selectSize="small"
          />
          <Box display={'flex'} gap="16px" alignContent={'center'}>
            {downloadButton}
            {onRefreshData && (
              <Button variant="secondary" onClick={onRefreshData} startIcon={<Reload />}>
                Refresh Data
              </Button>
            )}
          </Box>
        </Box>
      }
      headerDivider={false}
    />
  );
};
