import Table from 'components/Table';
import TableBodyWithStates from 'components/Table/TableBodyWithStates';
import isEmpty from 'lodash/isEmpty';
import useAppModal from 'hooks/useAppModal';
import { CUSTODIAN_INFO_TABS_STATUS, CustodianStatus } from 'utils/constants/custodians';
import { CUSTODIAN_MODAL, MODAL_ACTIONS } from 'shared/Modals/constants';
import { CustodianAccount } from 'utils/types/product';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import { Button } from 'components/Button/Button';
import { useMemo, useState } from 'react';
import { CustodianStepInfoTabs } from 'pages/Tokens/components/Form/PartnerDetails/components/CustodianInfoStep/components/CustodianInfoStepTabs';
import { CustodiansListProps } from 'pages/Tokens/components/Form/PartnerDetails/PartnerDetails.types';
import TableHeaderWithMultiSort from 'components/Table/TableHeaderWithMultiSort';
import { CustodiansTableColumnsConfig } from 'pages/Instruments/components/Form/PartnerDetails/components/CustodianInfoStep/components/CustodianTable/tableColumnsConfig';
import { CustodianTableRow } from 'pages/Instruments/components/Form/PartnerDetails/components/CustodianInfoStep/components/CustodianTable';
import Box from '@mui/material/Box';
import TableTitleWithActions from 'components/Table/TableTitleWithActions';

interface CustodiansTableProps {
  allCustodians: CustodiansListProps;
  partnersCustodians: string[];
  loadingCustodians: boolean;
  onCustodianListUpdate: () => void;
}

function CustodiansTable({
  loadingCustodians,
  allCustodians,
  partnersCustodians,
  onCustodianListUpdate,
}: CustodiansTableProps) {
  const openModal = useAppModal();
  const [activeTab, setActiveTab] = useState(CUSTODIAN_INFO_TABS_STATUS.ACTIVE);
  const custodians = useMemo(() => {
    return allCustodians?.filter((custodian) => {
      if (activeTab === CUSTODIAN_INFO_TABS_STATUS.DEACTIVATED)
        return custodian.status === CustodianStatus.DEACTIVATED;
      return custodian.status === CustodianStatus.ACTIVE;
    });
  }, [activeTab, allCustodians]);

  const handleChangeTab = (tab: CUSTODIAN_INFO_TABS_STATUS) => {
    setActiveTab(tab);
  };

  const openEditCustodianModal = (type: MODAL_ACTIONS, custodian?: CustodianAccount) => {
    openModal(
      {
        modalName: CUSTODIAN_MODAL,
        modalData: {
          data: {
            custodian: custodian ?? null,
            custodians: allCustodians?.filter(
              (custodian) => custodian.status === CustodianStatus.ACTIVE
            ),
            partnersCustodians: partnersCustodians,
          },
          custom: {
            isToken: true,
          },
          type: type,
        },
      },
      {
        onCloseModalAction: onCustodianListUpdate,
      }
    );
  };

  const openDeleteCustodianModal = (custodian: CustodianAccount) => {
    openModal(
      {
        modalName: CUSTODIAN_MODAL,
        modalData: {
          data: {
            custodian,
            custodians,
          },
          type: MODAL_ACTIONS.DELETE,
        },
      },
      {
        onCloseModalAction: onCustodianListUpdate,
      }
    );
  };

  return (
    <Box paddingLeft={2} paddingRight={2}>
      <TableTitleWithActions
        title="Custodian Accounts"
        actions={[
          <Button
            variant="secondary"
            onClick={() => openEditCustodianModal(MODAL_ACTIONS.CREATE)}
            type="button"
            size="medium"
            endIcon={<PlusIcon />}
          >
            Add Custodian
          </Button>,
        ]}
      />
      <Table
        tabsComponent={
          <Box paddingLeft={2}>
            <CustodianStepInfoTabs
              active={activeTab}
              onChangeTab={handleChangeTab}
            ></CustodianStepInfoTabs>
          </Box>
        }
        noPadding
      >
        <TableHeaderWithMultiSort showActionsCell={true} columns={CustodiansTableColumnsConfig} />
        <TableBodyWithStates
          loadingData={Boolean(loadingCustodians)}
          hasContent={!isEmpty(custodians)}
          noContentLabel="No custodians in the list."
        >
          {custodians?.map((custodian) => (
            <CustodianTableRow
              key={custodian._id}
              custodian={custodian}
              editAction={() => {
                openEditCustodianModal(MODAL_ACTIONS.EDIT, custodian);
              }}
              deleteAction={() => {
                openDeleteCustodianModal(custodian);
              }}
              activateAction={() => {
                openEditCustodianModal(MODAL_ACTIONS.CUSTOM, custodian);
              }}
            />
          ))}
        </TableBodyWithStates>
      </Table>
    </Box>
  );
}

export default CustodiansTable;
