export const millisecondsInOneMinute = 60000;
export const millisecondsInOneHour = 3600000;
export const UNAUTHORIZED_STATUS_CODE = 401;
export const ZERO_INDEX = 0;
export const THREE = 3;
export const MINUS_ONE = -1;

export const CRYPTO_DECIMAL_PLACES = 8;
export const PERCENTAGE_DECIMAL_PLACES = 4;
export const ACTUAL_POST_WEIGHT_DIFFERENCE_PERCENTAGE_DECIMALS = 10; //
export const FIAT_DECIMAL_PLACES = 4;
export const REBALANCE_PRICE_DECIMAL_PLACES = 4;
